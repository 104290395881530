<template>
	<b-modal
		v-model="setIsOpenModalFile"
		:title="title"
		centered
		size="custom"
		scrollable
		cancel-variant="light"
		@hidden="handleCloseItemsModal"
		no-close-on-backdrop
		no-close-on-esc
		:modal-class="'custom-modal'"
	>
		<div class="d-flex flex-column">
			<div v-if="fileItems.length === 0" class="d-flex justify-content-center flex-row align-items-center w-100">
				<span>{{ FormMSG(1, 'No data') }}</span>
			</div>
			<div v-else>
				<div class="d-flex flex-row align-items-center w-100" v-for="(item, index) of fileItems" :key="index">
					<FileAttachementItems ref="file-attachment-item" :id="item.id" :item="item" @deleteItemFile="deleteItemFile" />
				</div>
			</div>

			<div class="d-flex flex-row justify-content-end w-100">
				<div class="d-flex flex-row align-items-center mr-4">
					<button class="d-flex flex-row align-items-center btn-transparent gap-5 w-100" @click="handleAddNewFileItem">
						<div>
							<component :is="getLucideIcon(ICONS.PLUS_CIRCLE.name)" :size="22" :color="ICONS.PLUS_CIRCLE.color" />
						</div>
						<div>
							<div class="treeselect-label item-select">{{ FormMSG(2, 'Add attachment line') }}</div>
						</div>
					</button>
				</div>
			</div>
		</div>
		<template #modal-footer>
			<div class="d-flex justify-content-end align-items-center w-100 gap-5">
				<b-button variant="outline-danger" size="sm" style="width: 80px; border-radius: 5px" @click="handleCloseItemsModal">{{
					FormMSG(3, 'Close')
				}}</b-button>
				<b-button
					:disabled="fileItems.length === 0"
					variant="outline-success"
					size="sm"
					style="width: 80px; border-radius: 5px"
					@click="handleSaveItemsModal"
					>{{ FormMSG(4, 'Save') }}</b-button
				>
			</div>
		</template>
	</b-modal>
</template>

<script>
import FileAttachementItems from './FileAttachementItems.vue';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';

export default {
	name: 'AllAttachementFileModal',
	components: { FileAttachementItems },
	mixins: [languageMessages, globalMixin],
	props: {
		id: {
			type: String,
			required: false
		},
		title: {
			type: String,
			required: false
		},
		openModalFile: {
			type: Boolean,
			default: false,
			required: false
		}
	},
	data() {
		return {
			fileItems: []
		};
	},
	computed: {
		setIsOpenModalFile: {
			get() {
				return this.openModalFile;
			},
			set(newValue) {
				this.$emit('handleCloseModalFile', { value: newValue, id: this.id });
			}
		}
	},
	watch: {},
	created() {},
	methods: {
		handleAddNewFileItem() {
			let _id = this.fileItems.length + 1;
			this.fileItems = [...this.fileItems, { id: _id, file: null, fileTypeOrName: null }];
		},
		deleteItemFile(id) {
			this.fileItems = this.fileItems.filter((item) => item.id !== id);
		},
		handleCloseItemsModal() {
			this.fileItems = [];
			this.$emit('handleCloseModalFile', { value: false, id: this.id });
		},
		handleSaveItemsModal() {
			let data = [];
			let refs = this.$refs['file-attachment-item'];
			for (let i = 0; i < refs.length; i++) {
				data.push(refs[i].attachment);
			}
			this.$emit('setAttachmentFiles', data);
			this.fileItems = [];
		}
	}
};
</script>

<style lang="scss" scoped>
.gap-5 {
	gap: 5px;
}
.gap-10 {
	gap: 10px;
}
.custom-modal {
	max-width: 1200px;
}
.item-select {
	display: flex;
	align-items: center;
	font-weight: 600;
	height: 30px;
	letter-spacing: 0.01em;
	color: rgba(6, 38, 62, 0.74);
}
</style>
