<template>
	<div class="animated fadeIn" :class="$screen.width > 576 ? 'container-layout' : 'container-mobile'">
		<b-form @submit.prevent="submitForm">
			<b-row class="d-flex align-items-center pb-2">
				<b-col cols="6">
					<div class="w-50">
						<b-form-group :label="FormMSG(1, 'Date')">
							<v-date-picker
								v-model="wasteFormKey.date"
								:available-dates="{
									start: null,
									end: null
								}"
								:locale="lang"
								:masks="masks"
								@dayclick="handleChangeDate"
							>
								<template v-slot="{ inputValue, togglePopover }">
									<b-input-group>
										<b-form-input :value="inputValue" class="br-none" readonly />
										<b-input-group-append>
											<b-input-group-text class="cursor-pointer bg-color-white input-group-text-bg-white" @click="togglePopover()">
												<component :is="getLucideIcon('Calendar')" color="rgba(6, 38, 62, .6)" :size="18" :stroke-width="2.25" />
											</b-input-group-text>
										</b-input-group-append>
									</b-input-group>
								</template>
							</v-date-picker>
						</b-form-group>
					</div>
				</b-col>
				<b-col cols="6">
					<div class="d-flex justify-content-end align-items-center">
						<div class="text-booking font-weight-bold mr-3">{{ FormMSG(2, 'Waste n°') }}</div>
						<div class="number-booking py-2 px-4 font-weight-bold">{{ setWasteNumber(wasteKeyModal) }}</div>
					</div>
				</b-col>
			</b-row>
			<b-row>
				<b-col cols="12">
					<b-row class="d-flex flex-row justify-content-center align-items-center flex-wrap mb-2">
						<div class="ml-3">
							<b-button class="btn-plus" variant="light" size="sm" @click="showNote = !showNote">
								<component :is="getLucideIcon('Plus')" color="#06263E" :size="16" :stroke-width="2" />
							</b-button>
						</div>
						<div class="divider flex-grow-1 ml-3"></div>
						<div class="p-2">{{ FormMSG(3, 'Add Note') }}</div>
						<div class="divider flex-grow-1 mr-3"></div>
					</b-row>
					<b-collapse :visible="showNote" id="collapse-1" class="mt-2">
						<b-row v-if="showNote">
							<b-col cols="12">
								<b-form-textarea id="textarea" v-model="wasteFormKey.note" placeholder="Enter something..." rows="3" max-rows="6">{{
									wasteFormKey.note
								}}</b-form-textarea>
								<div class="mt-3 mb-0"></div>
							</b-col>
						</b-row>
					</b-collapse>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<b-row v-if="recordingItems.length === 0" class="mt-3 mb-3">
							<b-col cols="12" class="d-flex justify-content-center text-title">{{ FormMSG(4, 'No data item for recording') }}</b-col>
						</b-row>
						<div v-else>
							<div v-for="(item, index) of recordingItems" :key="index">
								<waste-form-item
									ref="form-item"
									@handleDeleteItem="handleDeleteItem"
									@setReccordingSelect="setReccordingSelect"
									:id="item.id"
									:AllDepartments="AllDepartments"
									:premises="premises"
									:locations="locations"
									:oneItem="item"
									:action="action"
								/>
							</div>
						</div>
						<b-row class="mt-3 mb-3">
							<b-col cols="6" class="d-flex justify-content-start">
								<b-button size="sm" variant="outline-none" class="text-white w-138-px bg-1" @click="redirectPo" block>{{
									FormMSG(5, 'Create PO')
								}}</b-button>
							</b-col>
							<b-col cols="6" class="d-flex justify-content-end">
								<b-button
									@click="handleAddItemReccording"
									size="sm"
									variant="outline-none"
									class="w-138-px d-flex justify-content-end w-auto"
									block
								>
									<span class="d-flex justify-content-center text-title">
										<component
											:is="getLucideIcon(ICONS.PLUS_CIRCLE.name)"
											:color="ICONS.PLUS_CIRCLE.color"
											:size="16"
											:stroke-width="2.25"
										/>
										&nbsp;{{ FormMSG(6, 'Add Item') }}
									</span>
								</b-button>
							</b-col>
						</b-row>
					</fieldset>
				</b-col>
			</b-row>
			<b-row>
				<b-col
					class="d-flex justify-content-center mt-2"
					sm="4"
					md="4"
					lg="4"
					xl="4"
					offset-sm="5"
					offset-md="5"
					offset-lg="5"
					offset-xl="5"
					:style="`display: ${displayAddBtn ? 'block' : 'none'} !important;`"
				>
					<b-button ref="submit" type="submit" size="md" variant="outline-primary" class="w-138-px" block>{{ FormMSG(7, 'Save') }}</b-button>
				</b-col>
			</b-row>
			<delete-wastes
				:title="titleModalDelete"
				:open="showModalDelete"
				@closeModalOpen="closeModalOpen"
				@handleDeleteWasteItem="handleDeleteWasteItem"
				:typeDelete="typeDelete"
			/>
		</b-form>
	</div>
</template>
<script>
import DeleteWastes from '../../views/waste/modal/DeleteWastes.vue';
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import { validationMixin } from 'vuelidate';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import _ from 'lodash';
import { generateSecureId } from '@/shared/utils';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import InputDateTimePicker from '@/components/InputDateTimePicker/InputDateTimePicker';
import WasteFormItem from '@/components/Waste/FormItem';
import moment from 'moment';
import { delWasteDetail, newWasteDetailInit } from '@/cruds/waste.crud';

export default {
	name: 'WasteForm',
	mixins: [LanguageMessages, GlobalMixin, validationMixin],
	data() {
		return {
			generateSecureId,
			recordingItems: [],
			selectedPerson: 0,
			users: [],
			departments: [],
			showNote: false,
			selectedUser: [],
			statusSelected: 0,
			masks: {
				input: 'DD/MM/YYYY'
			},
			wasteFormKey: {
				id: null,
				wasteNumber: null,
				date: new Date(),
				note: '',
				idToUpdate: null
			},
			openModalFile: false,
			initAttachement: false,
			showModalDelete: false,
			titleModalDelete: '',
			typeDelete: '',
			itemToDelete: null
		};
	},
	components: {
		Treeselect,
		'v-date-picker': DatePicker,
		InputDateTimePicker,
		WasteFormItem,
		DeleteWastes
	},
	props: {
		action: {
			type: String,
			required: false,
			default: 'ADD' // ADD, UPDATE
		},
		displayAddBtn: {
			type: Boolean,
			required: false,
			default: true
		},
		isDeleteAllItem: {
			type: Boolean,
			default: false
		},
		AllDepartments: {
			type: Array,
			default: () => [],
			required: false
		},
		premises: {
			type: Array,
			default: () => [],
			required: false
		},
		locations: {
			type: Array,
			default: () => [],
			required: false
		},
		wasteKeyModal: {
			type: Object,
			default: () => ({}),
			required: false
		},
		dataSlice: {
			type: Array,
			default: () => [],
			required: false
		},
		value: {
			type: Boolean,
			required: false,
			default: false
		},
		editData: {
			type: Object,
			required: false,
			default: () => ({})
		},
		itemsSelected: {
			type: Array,
			required: false
		}
	},
	watch: {
		action: {
			handler(newVal) {
				return newVal;
			},
			immedite: true,
			deep: true
		},
		recordingItems: {
			handler(newVal) {
				return newVal;
			},
			immediate: true,
			deep: true
		},
		isDeleteAllItem: {
			handler(newVal) {
				this.handleDeleteAllItems(this.recordingItems, newVal);
				return newVal;
			},
			immediate: true,
			deep: true
		},
		value: {
			handler(newVal) {
				return newVal;
			},
			immediate: true,
			deep: true
		},
		wasteKeyModal: {
			handler(newVal) {
				if (newVal !== null) {
					this.wasteFormKey = {
						...this.wasteFormKey,
						wasteNumber: +newVal.wasteNumber
					};
					return newVal;
				}
				return newVal;
			},
			immediate: true,
			deep: true
		},
		dataSlice: {
			handler(newVal) {
				if (newVal) {
					this.recordingItems = newVal;
					return newVal;
				}
				return newVal;
			},
			immediate: true,
			deep: true
		},
		editData: {
			handler(newVal) {
				if (Object.keys(newVal).length !== 0) {
					this.wasteFormKey = {
						...this.wasteFormKey,
						id: +newVal.wasteId,
						wasteNumber: +newVal.waste.wasteNumber,
						date: newVal.waste.entryDate,
						note: newVal.waste.note,
						idToUpdate: +newVal.id
					};
					if (this.wasteFormKey.note.length !== 0) {
						this.showNote = true;
					}
					return newVal;
				}
				return newVal;
			},
			immediate: true,
			deep: true
		}
	},
	computed: {},

	async created() {},
	methods: {
		redirectPo() {
			let url = window.location.href.split('#')[0];
			window.location.href = `${url}#/mypo`;
			let redirect = window.location.href;
			return redirect;
		},
		setReccordingSelect(e) {
			let _item = e.item;
			let _event = e.event;
			this.recordingItems = this.recordingItems.map((item) => {
				if (+item.id === +_item.id) {
					return { ...item, isSelected: _event };
				}
				return item;
			});
			this.$emit('setIsSelectedItem', this.recordingItems);
		},
		async submitForm(e) {
			e.preventDefault();
			let checks = [];
			let data = this.$refs['form-item'];
			if (data !== undefined) {
				for (let i = 0; i < data.length; i++) {
					checks.push(data[i].checkIsRequired());
				}
			}
			return checks.length === 0 ? false : checks.every((value) => value === false);
		},
		setWasteNumber(data) {
			if (data !== null) {
				return data.wasteNumber;
			}
		},
		handleChangeDate(payload) {
			let dateChange = moment(payload.date).utc().format();
			this.wasteFormKey = {
				...this.wasteFormKey,
				date: dateChange
			};
		},
		async handleAddItemReccording() {
			let initId = await newWasteDetailInit();
			this.recordingItems = [
				...this.recordingItems,
				{
					id: initId.toString(),
					reason: 1,
					isAddItem: true,
					type: null,
					measurement: null,
					department: null,
					premisesLocation: null,
					disposalType: null,
					attachement: null,
					unite: null,
					uniteType: null,
					idToUpdate: null,
					images: [],
					isSelected: false
				}
			];
		},
		async handleDeleteItem(data) {
			this.showModalDelete = true;
			this.typeDelete = 'OneItem';
			this.titleModalDelete = `${this.FormMSG(8, 'Are you sure you want to delete all entries for waste record number')} ${data.id}?`;
			this.itemToDelete = data;
		},
		closeModalOpen() {
			this.showModalDelete = false;
			this.itemToDelete = null;
			this.typeDelete = '';
		},
		async handleDeleteWasteItem() {
			if (this.itemToDelete !== null) {
				await delWasteDetail(+this.itemToDelete.id);
				this.recordingItems = this.recordingItems.filter((item) => +item.id !== +this.itemToDelete.id);
				const message = this.FormMSG(9, 'The entries for waste record delete successfully.');
				this.createToastForMobile('Success', message, '', 'success');
				this.itemToDelete = null;
			}
			this.showModalDelete = false;
			this.typeDelete = '';
		},
		async handleDeleteAllItems(data, event) {
			this.recordingItems = data.map((item) => ({ ...item, isSelected: event }));
		}
	},
	validations() {}
};
</script>
<style lang="scss">
.bg-1 {
	background-color: #3d3b8f6e;
}
.btn-plus {
	border-radius: 5px;
}
</style>
