<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		v-model="value"
		:title="title"
		size="xl"
		centered
		cancel-variant="light"
		:modal-class="['custom-modal-min-width']"
	>
		<template #modal-header>
			<div class="d-flex align-items-center w-100">
				<div class="w-50">
					<h5 class="modal-title">{{ title }}</h5>
				</div>
				<div class="d-flex flex-row justify-content-end align-items-center w-50 gap-5">
					<button type="button" class="btn-close-custom-modal" @click="cancelSettings">×</button>
				</div>
			</div>
		</template>
		<div class="container-layout">
			<!-- Standard waste-->
			<div class="mb-4">
				<h4 class="card-title"><strong>Standard waste</strong></h4>
				<b-collapse :visible="true">
					<b-row>
						<b-col cols="5">
							<b-row>
								<b-col>
									<custom-form-group
										:label="FormMSG(6, 'Type')"
										v-model="wasteTypes.standard.cardboard"
										:is-for-number="false"
										:isDisable="true"
										:addUnit="false"
									/>
								</b-col>
								<b-col>
									<b-form-group :label="FormMSG(7, 'Unit')">
										<b-form-select
											:options="getUnitsList(kgGarbageContainerUnitsType)"
											v-model="dataToSend.cardboardUnit"
											id="optionForUnits"
										></b-form-select>
									</b-form-group>
								</b-col> </b-row
						></b-col>
						<b-col cols="5" offset="1">
							<b-row>
								<b-col>
									<custom-form-group
										:label="FormMSG(6, 'Type')"
										v-model="wasteTypes.standard.generalWaste"
										:is-for-number="false"
										:isDisable="true"
										:addUnit="false"
									/>
								</b-col>
								<b-col>
									<b-form-group :label="FormMSG(7, 'Unit')">
										<b-form-select
											:options="getUnitsList(kgContainerUnitsType)"
											v-model="dataToSend.generalWasteUnit"
											id="optionForUnits"
										></b-form-select>
									</b-form-group>
								</b-col>
							</b-row>
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="5">
							<b-row>
								<b-col>
									<custom-form-group v-model="wasteTypes.standard.paper" :is-for-number="false" :isDisable="true" :addUnit="false" />
								</b-col>
								<b-col>
									<b-form-group>
										<b-form-select
											:options="getUnitsList(kgGarbageContainerUnitsType)"
											v-model="dataToSend.paperUnit"
											id="optionForUnits"
										></b-form-select>
									</b-form-group>
								</b-col> </b-row
						></b-col>
						<b-col cols="5" offset="1">
							<b-row>
								<b-col>
									<custom-form-group v-model="wasteTypes.standard.food" :is-for-number="false" :isDisable="true" :addUnit="false" />
								</b-col>
								<b-col>
									<b-form-group>
										<b-form-select
											:options="getUnitsList(kgGarbagesUnitsType)"
											v-model="dataToSend.foodUnit"
											id="optionForUnits"
										></b-form-select>
									</b-form-group>
								</b-col>
							</b-row>
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="5">
							<b-row>
								<b-col>
									<custom-form-group v-model="wasteTypes.standard.plastic" :is-for-number="false" :isDisable="true" :addUnit="false" />
								</b-col>
								<b-col>
									<b-form-group>
										<b-form-select
											:options="getUnitsList(kgGarbageContainerUnitsType)"
											v-model="dataToSend.plasticUnit"
											id="optionForUnits"
										></b-form-select>
									</b-form-group>
								</b-col> </b-row
						></b-col>
						<b-col cols="5" offset="1">
							<b-row>
								<b-col>
									<custom-form-group v-model="wasteTypes.standard.glass" :is-for-number="false" :isDisable="true" :addUnit="false" />
								</b-col>
								<b-col>
									<b-form-group>
										<b-form-select
											:options="getUnitsList(kgBottleContainerType)"
											v-model="dataToSend.glassUnit"
											id="optionForUnits"
										></b-form-select>
									</b-form-group>
								</b-col>
							</b-row>
						</b-col>
					</b-row>
				</b-collapse>
			</div>
			<!-- Standard waste-->
			<!-- Set destruction waste-->
			<div class="mt-4">
				<h4 class="card-title"><strong>Set destruction waste</strong></h4>
				<b-collapse :visible="true">
					<b-row>
						<b-col cols="5">
							<b-row>
								<b-col>
									<custom-form-group
										:label="FormMSG(6, 'Type')"
										v-model="wasteTypes.setDestruction.paint"
										:is-for-number="false"
										:isDisable="true"
										:addUnit="false"
									/>
								</b-col>
								<b-col>
									<b-form-group :label="FormMSG(7, 'Unit')">
										<b-form-select
											:options="getUnitsList(kgPaintLitersType)"
											v-model="dataToSend.paintSpecialWasteUnit"
											id="optionForUnits"
										></b-form-select>
									</b-form-group>
								</b-col> </b-row
						></b-col>
						<b-col cols="5" offset="1">
							<b-row>
								<b-col>
									<custom-form-group
										:label="FormMSG(6, 'Type')"
										v-model="wasteTypes.setDestruction.wood"
										:is-for-number="false"
										:isDisable="true"
										:addUnit="false"
									/>
								</b-col>
								<b-col>
									<b-form-group :label="FormMSG(7, 'Unit')">
										<b-form-select
											:options="getUnitsList(kgMetersCubeType)"
											v-model="dataToSend.woodUnit"
											id="optionForUnits"
										></b-form-select>
									</b-form-group>
								</b-col>
							</b-row>
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="5">
							<b-row>
								<b-col>
									<custom-form-group v-model="wasteTypes.setDestruction.plastic" :is-for-number="false" :isDisable="true" :addUnit="false" />
								</b-col>
								<b-col>
									<b-form-group>
										<b-form-select
											:options="getUnitsList(kgMetersCubeType)"
											v-model="dataToSend.plasticDestructionUnit"
											id="optionForUnits"
										></b-form-select>
									</b-form-group>
								</b-col> </b-row
						></b-col>
						<b-col cols="5" offset="1">
							<b-row>
								<b-col>
									<custom-form-group v-model="wasteTypes.setDestruction.steel" :is-for-number="false" :isDisable="true" :addUnit="false" />
								</b-col>
								<b-col>
									<b-form-group>
										<b-form-select
											:options="getUnitsList(kgMetersCubeType)"
											v-model="dataToSend.steelUnit"
											id="optionForUnits"
										></b-form-select>
									</b-form-group>
								</b-col>
							</b-row>
						</b-col>
					</b-row>
				</b-collapse>
			</div>
			<!-- Set destruction waste-->
		</div>
		<!-- Warning on save -->
		<b-modal
			header-class="header-class-modal-doc-package"
			header-bg-variant="warning"
			:title="FormMSG(18, 'Warning')"
			class="modal-warning"
			v-model="showUnitChangeWarning"
			centered
		>
			<div>
				{{ FormMSG(19, 'Your change will affect all units already used in this project.') }}<br />
				{{ FormMSG(20, 'Are you sure you want to apply this update?') }}
			</div>
			<template #modal-footer>
				<div class="w-100">
					<b-row class="padding-c-1">
						<b-col cols="12">
							<div class="d-flex flex-row justify-content-center align-items-center w-100" style="gap: 20px">
								<b-button
									size="md"
									variant="outline-danger"
									:disabled="watingSubmitForm"
									class="w-100-px"
									@click="showUnitChangeWarning = false"
								>
									{{ FormMSG(21, 'No') }}
								</b-button>
								<b-button
									size="md"
									variant="success"
									class="w-138-px d-flex justify-content-center align-items-center"
									:disabled="watingSubmitForm"
									@click="saveUnits"
								>
									<b-spinner v-show="watingSubmitForm" small />
									<div :class="`${watingSubmitForm ? 'pl-2' : ''}`" style="margin-top: 1px">
										{{ FormMSG(22, 'Yes') }}
									</div>
								</b-button>
							</div>
						</b-col>
					</b-row>
				</div>
			</template>
		</b-modal>
		<!-- Warning on save -->
		<template #modal-footer>
			<div class="w-100">
				<b-row class="padding-c-1">
					<b-col cols="12">
						<div class="d-flex flex-row justify-content-end align-items-center w-100">
							<b-button size="md" variant="custom-outline-gray" class="w-138-px mr-3" @click="cancelSettings">
								{{ !btnCloseOnly ? FormMSG(1, 'Cancel') : FormMSG(3, 'Close') }}
							</b-button>
							<b-button size="md" variant="primary" class="w-138-px" @click="askForUnitsChange" v-show="!btnCloseOnly">
								<div style="margin-top: 1px">{{ FormMSG(2, 'Save') }}</div>
							</b-button>
						</div>
					</b-col>
				</b-row>
			</div>
		</template>
	</b-modal>
</template>

<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import CustomFormGroup from '@/components/Forms/CustomFormGroup';
import { updateWasteUnit } from '@/cruds/waste.crud';
import { store } from '@/store';
import _ from 'lodash';

export default {
	name: 'WasteUnitSettingsModal',
	mixins: [LanguageMessages, GlobalMixin],
	data() {
		return {
			closeEvent: 'waste-unit-settings-modal:closed',
			saveEvent: 'waste-unit-settings-modal:save',
			showUnitChangeWarning: false,
			watingSubmitForm: false,
			btnCloseOnly: false,
			optionForUnits: [],
			currentProject: {},
			kgGarbageContainerUnitsType: [0, 1, 4],
			kgContainerUnitsType: [0, 4],
			kgGarbagesUnitsType: [0, 1, 2],
			kgBottleContainerType: [0, 3, 4],
			kgPaintLitersType: [0, 5],
			kgMetersCubeType: [0, 6],
			dataToSend: {
				cardboardUnit: 0,
				paperUnit: 0,
				plasticUnit: 0,
				generalWasteUnit: 0,
				foodUnit: 0,
				glassUnit: 0,
				paintSpecialWasteUnit: 0,
				plasticDestructionUnit: 0,
				woodUnit: 0,
				steelUnit: 0
			}
		};
	},
	props: {
		value: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	watch: {
		value: {
			handler(newVal) {
				return newVal;
			},
			immediate: true,
			deep: true
		}
	},
	components: {
		CustomFormGroup
	},
	computed: {
		title() {
			return this.FormMSG(5, 'Waste units settings');
		},
		wasteTypes() {
			return {
				standard: {
					cardboard: this.FormMSG(8, 'Cardboard'),
					generalWaste: this.FormMSG(9, 'General waste'),
					paper: this.FormMSG(10, 'Paper'),
					food: this.FormMSG(11, 'Food'),
					plastic: this.FormMSG(12, 'Plastic'),
					glass: this.FormMSG(13, 'Glass')
				},
				setDestruction: {
					paint: this.FormMSG(14, 'Paint/Special waste'),
					wood: this.FormMSG(15, 'Wood'),
					plastic: this.FormMSG(12, 'Plastic'),
					steel: this.FormMSG(17, 'Steel')
				}
			};
		}
	},
	created() {
		this.currentProject = store.getCurrentProjectConfig();
		this.optionForUnits = this.FormMenu(1452);
		this.dataToSend = {
			cardboardUnit: this.currentProject.cardboardUnit ? this.currentProject.cardboardUnit : 0,
			paperUnit: this.currentProject.paperUnit ? this.currentProject.paperUnit : 0,
			plasticUnit: this.currentProject.plasticUnit ? this.currentProject.plasticUnit : 0,
			generalWasteUnit: this.currentProject.generalWasteUnit ? this.currentProject.generalWasteUnit : 0,
			foodUnit: this.currentProject.foodUnit ? this.currentProject.foodUnit : 0,
			glassUnit: this.currentProject.glassUnit ? this.currentProject.glassUnit : 0,
			paintSpecialWasteUnit: this.currentProject.paintSpecialWasteUnit ? this.currentProject.paintSpecialWasteUnit : 0,
			plasticDestructionUnit: this.currentProject.plasticDestructionUnit ? this.currentProject.plasticDestructionUnit : 0,
			woodUnit: this.currentProject.woodUnit ? this.currentProject.woodUnit : 0,
			steelUnit: this.currentProject.steelUnit ? this.currentProject.steelUnit : 0
		};
	},
	methods: {
		getUnitsList(types) {
			if (this.optionForUnits[0]) {
				return this.optionForUnits.filter((e) => types.includes(e.value));
			}
			return [];
		},
		askForUnitsChange() {
			this.showUnitChangeWarning = true;
		},
		cancelSettings() {
			this.dataToSend = {
				cardboardUnit: this.currentProject.cardboardUnit ? this.currentProject.cardboardUnit : 0,
				paperUnit: this.currentProject.paperUnit ? this.currentProject.paperUnit : 0,
				plasticUnit: this.currentProject.plasticUnit ? this.currentProject.plasticUnit : 0,
				generalWasteUnit: this.currentProject.generalWasteUnit ? this.currentProject.generalWasteUnit : 0,
				foodUnit: this.currentProject.foodUnit ? this.currentProject.foodUnit : 0,
				glassUnit: this.currentProject.glassUnit ? this.currentProject.glassUnit : 0,
				paintSpecialWasteUnit: this.currentProject.paintSpecialWasteUnit ? this.currentProject.paintSpecialWasteUnit : 0,
				plasticDestructionUnit: this.currentProject.plasticDestructionUnit ? this.currentProject.plasticDestructionUnit : 0,
				woodUnit: this.currentProject.woodUnit ? this.currentProject.woodUnit : 0,
				steelUnit: this.currentProject.steelUnit ? this.currentProject.steelUnit : 0
			};
			this.$emit(this.closeEvent);
		},
		async saveUnits() {
			this.watingSubmitForm = true;
			try {
				const projectUnit = await updateWasteUnit(this.dataToSend);
				this.currentProject = { ...this.currentProject, ...projectUnit };
				store.setCurrentProjectConfig(this.currentProject);
			} catch (error) {
				console.log(error);
			}
			this.watingSubmitForm = false;
			this.showUnitChangeWarning = false;
			this.$emit(this.saveEvent);
		}
	}
};
</script>

<style>
.padding-c-1 {
	padding-right: 30px;
	padding-left: 30px;
}
.btn-close-custom-modal {
	display: flex;
	font-size: 2.5rem;
	line-height: 1;
	color: #000;
	text-shadow: 0 1px 0 #fff;
	opacity: 0.5;
	border: none;
	border-color: none;
	appearance: none;
	cursor: pointer;
	background-color: transparent;
	border: 0;
}
.custom-modal-min-width .modal-dialog {
	min-width: 600px;
}
</style>
