<template>
	<div class="d-flex flex-row justify-content-around align-items-center justify-content-center flex-wrap w-100">
		<div class="d-flex flex-column flex-grow-0 align-items-end mt-4">
			<b-form-group class="pd-10">
				<b-button class="btn bg-transparent border-0" variant="primary" size="sm">
					<component :is="getLucideIcon(ICONS.COMPONENT.name)" :color="ICONS.COMPONENT.color" :size="20" class="rotate-icon" />
				</b-button>
			</b-form-group>
		</div>
		<div class="mt-2" style="width: 200px">
			<b-form-group :label="FormMSG(1, 'file uploaded')">
				<b-input-group>
					<b-form-file v-model="attachment.file" :placeholder="FormMSG(2, 'choose file')" ref="file-input" size="lg"> </b-form-file>
					<template #append>
						<b-input-group-text class="custom-file-round">
							<button class="btn-transparent" @click="handleClickFileInput">
								<component :is="getLucideIcon('File')" :size="20" color="#47C7BF" />
							</button>
						</b-input-group-text>
					</template>
				</b-input-group>
			</b-form-group>
		</div>
		<div style="width: 150px">
			<b-form-group :label="FormMSG(3, 'file type/name')">
				<treeselect
					v-model="attachment.fileTypeOrName.id"
					:multiple="false"
					:disable-branch-nodes="true"
					:placeholder="FormMSG(4, 'Type')"
					:options="attachmentsOptions"
					@select="(payload) => handleChange(payload)"
				>
					<div class="d-flex flex-row align-items-center" slot="option-label" slot-scope="{ node }">
						<div class="treeselect-label item-select" :title="node.label">{{ node.label }}</div>
					</div>
				</treeselect>
			</b-form-group>
		</div>
		<div class="d-flex flex-column flex-grow-0 align-items-start mt-4">
			<b-form-group class="pd-10">
				<b-button class="btn bg-transparent border-0" variant="primary" size="sm" @click="deleteItem(id)">
					<component :is="getLucideIcon(ICONS.MINUS_CIRCLE.name)" :color="ICONS.MINUS_CIRCLE.color" :size="18" />
				</b-button>
			</b-form-group>
		</div>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import Treeselect from '@riophae/vue-treeselect';

export default {
	name: 'FileattachmentItems',
	components: { Treeselect },
	mixins: [languageMessages, globalMixin],
	props: {
		id: {
			type: Number,
			required: false
		},
		item: {
			type: Object,
			required: false
		}
	},
	data() {
		return {
			attachment: {
				file: null,
				fileTypeOrName: {
					id: null,
					label: null
				}
			}
		};
	},
	computed: {
		attachmentsOptions() {
			let data = [
				{
					id: 1,
					label: this.FormMSG(5, 'Receipt')
				},
				{
					id: 2,
					label: this.FormMSG(6, 'Invoice')
				}
			];
			return data;
		}
	},
	watch: {},
	created() {},
	methods: {
		handleClickFileInput() {
			let fileInput = this.$refs['file-input'].$refs['input'].click();
			return fileInput;
		},
		deleteItem(id) {
			this.$emit('deleteItemFile', id);
		},
		handleChange(payload) {
			this.attachment = {
				...this.attachment,
				fileTypeOrName: {
					...this.attachment.fileTypeOrName,
					...payload
				}
			};
		}
	}
};
</script>

<style lang="scss" scoped>
.pd-10 {
	padding: 10px 0px;
}
.rotate-icon {
	transform: rotate(45deg);
}
.custom-file-round {
	background-color: #ffffff;
	border: 1px solid #cad0d7;
	border-radius: 8px;
	letter-spacing: 0.02em;
	color: rgba(6, 38, 62, 0.64);
	font-weight: 500;
	font-size: 13px;
	height: 35px;
}
</style>
