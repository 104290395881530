var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "d-flex flex-row justify-content-around align-items-center justify-content-center flex-wrap w-100",
    },
    [
      _c(
        "div",
        { staticClass: "d-flex flex-column flex-grow-0 align-items-end mt-4" },
        [
          _c(
            "b-form-group",
            { staticClass: "pd-10" },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn bg-transparent border-0",
                  attrs: { variant: "primary", size: "sm" },
                },
                [
                  _c(_vm.getLucideIcon(_vm.ICONS.COMPONENT.name), {
                    tag: "component",
                    staticClass: "rotate-icon",
                    attrs: { color: _vm.ICONS.COMPONENT.color, size: 20 },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-2", staticStyle: { width: "200px" } },
        [
          _c(
            "b-form-group",
            { attrs: { label: _vm.FormMSG(1, "file uploaded") } },
            [
              _c(
                "b-input-group",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "append",
                      fn: function () {
                        return [
                          _c(
                            "b-input-group-text",
                            { staticClass: "custom-file-round" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn-transparent",
                                  on: { click: _vm.handleClickFileInput },
                                },
                                [
                                  _c(_vm.getLucideIcon("File"), {
                                    tag: "component",
                                    attrs: { size: 20, color: "#47C7BF" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("b-form-file", {
                    ref: "file-input",
                    attrs: {
                      placeholder: _vm.FormMSG(2, "choose file"),
                      size: "lg",
                    },
                    model: {
                      value: _vm.attachment.file,
                      callback: function ($$v) {
                        _vm.$set(_vm.attachment, "file", $$v)
                      },
                      expression: "attachment.file",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { width: "150px" } },
        [
          _c(
            "b-form-group",
            { attrs: { label: _vm.FormMSG(3, "file type/name") } },
            [
              _c("treeselect", {
                attrs: {
                  multiple: false,
                  "disable-branch-nodes": true,
                  placeholder: _vm.FormMSG(4, "Type"),
                  options: _vm.attachmentsOptions,
                },
                on: { select: (payload) => _vm.handleChange(payload) },
                scopedSlots: _vm._u([
                  {
                    key: "option-label",
                    fn: function ({ node }) {
                      return _c(
                        "div",
                        { staticClass: "d-flex flex-row align-items-center" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "treeselect-label item-select",
                              attrs: { title: node.label },
                            },
                            [_vm._v(_vm._s(node.label))]
                          ),
                        ]
                      )
                    },
                  },
                ]),
                model: {
                  value: _vm.attachment.fileTypeOrName.id,
                  callback: function ($$v) {
                    _vm.$set(_vm.attachment.fileTypeOrName, "id", $$v)
                  },
                  expression: "attachment.fileTypeOrName.id",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "d-flex flex-column flex-grow-0 align-items-start mt-4",
        },
        [
          _c(
            "b-form-group",
            { staticClass: "pd-10" },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn bg-transparent border-0",
                  attrs: { variant: "primary", size: "sm" },
                  on: {
                    click: function ($event) {
                      return _vm.deleteItem(_vm.id)
                    },
                  },
                },
                [
                  _c(_vm.getLucideIcon(_vm.ICONS.MINUS_CIRCLE.name), {
                    tag: "component",
                    attrs: { color: _vm.ICONS.MINUS_CIRCLE.color, size: 18 },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }