var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        "header-class": "header-class-modal-doc-package",
        title: _vm.title,
        size: "xxl",
        centered: "",
        "cancel-variant": "light",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "modal-class": ["custom-modal-min-width"],
      },
      on: { show: _vm.resetModal, hidden: _vm.emitEventClose },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function () {
            return [
              _c("div", { staticClass: "d-flex align-items-center w-100" }, [
                _c("div", { staticClass: "w-50" }, [
                  _c("h5", { staticClass: "modal-title" }, [
                    _vm._v(_vm._s(_vm.title)),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-row justify-content-end align-items-center w-50 gap-5",
                  },
                  [
                    _c(_vm.getLucideIcon(_vm.ICONS.MESSAGE_CIRCLE.name), {
                      tag: "component",
                      attrs: {
                        color: _vm.ICONS.MESSAGE_CIRCLE.color,
                        "stroke-width": 1.25,
                        size: 30,
                        fill: _vm.ICONS.MESSAGE_CIRCLE.color,
                      },
                    }),
                    _c(
                      "button",
                      {
                        staticClass: "btn-close-custom-modal",
                        attrs: { type: "button" },
                        on: { click: _vm.emitEventClose },
                      },
                      [_vm._v("×")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          },
          proxy: true,
        },
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "w-100" },
                [
                  _c(
                    "b-row",
                    { staticClass: "padding-c-1" },
                    [
                      _c("b-col", { attrs: { cols: "6" } }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "w-100 d-flex flex-row justify-content-start align-items-center",
                          },
                          [
                            _c("b-button-group", [
                              _c(
                                "div",
                                { staticClass: "position-check" },
                                [
                                  _c("b-form-checkbox", {
                                    attrs: {
                                      disabled:
                                        _vm.setDisabledWhenNoRecording(),
                                      size: "lg",
                                    },
                                    on: { change: _vm.handleInputSelectAll },
                                    model: {
                                      value: _vm.selectAll,
                                      callback: function ($$v) {
                                        _vm.selectAll = $$v
                                      },
                                      expression: "selectAll",
                                    },
                                  }),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "btn-custom-delete w-138-px",
                                      attrs: {
                                        disabled:
                                          _vm.itemsSelected.length === 0,
                                        size: "md",
                                        variant: "outline-none",
                                      },
                                      on: {
                                        click: _vm.openModalDeleteAllSelected,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.FormMSG(4, "Delete All")) +
                                          " " +
                                          _vm._s(_vm.setCompte())
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]),
                      _c("b-col", { attrs: { cols: "6" } }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-row justify-content-end align-items-center w-100",
                          },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "w-138-px mr-3",
                                attrs: {
                                  size: "md",
                                  variant: "custom-outline-gray",
                                  disabled: _vm.watingSubmitForm,
                                },
                                on: { click: _vm.emitEventClose },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      !_vm.btnCloseOnly
                                        ? _vm.FormMSG(1, "Cancel")
                                        : _vm.FormMSG(3, "Close")
                                    ) +
                                    "\n\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.btnCloseOnly,
                                    expression: "!btnCloseOnly",
                                  },
                                ],
                                staticClass: "w-138-px",
                                attrs: {
                                  size: "md",
                                  variant: "primary",
                                  disabled: _vm.watingSubmitForm,
                                },
                                on: { click: _vm.dispatchEvent },
                              },
                              [
                                _c("b-spinner", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.watingSubmitForm,
                                      expression: "watingSubmitForm",
                                    },
                                  ],
                                  attrs: { small: "" },
                                }),
                                _c(
                                  "div",
                                  {
                                    class: `${
                                      _vm.watingSubmitForm ? "pl-2" : ""
                                    }`,
                                    staticStyle: { "margin-top": "1px" },
                                  },
                                  [_vm._v(_vm._s(_vm.FormMSG(2, "Save")))]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "div",
        { staticClass: "container-layout" },
        [
          _c("waste-form", {
            ref: "form",
            attrs: {
              action: _vm.action,
              "enable-btn-report": false,
              "display-add-btn": false,
              isDeleteAllItem: _vm.isDeleteAllItem,
              AllDepartments: _vm.AllDepartments,
              premises: _vm.premises,
              locations: _vm.locations,
              wasteKeyModal: _vm.wasteKeyModal,
              dataSlice: _vm.dataSlice,
              value: _vm.value,
              editData: _vm.editData,
              itemsSelected: _vm.itemsSelected,
            },
            on: {
              setIsDeleteAllItemAction: _vm.setIsDeleteAllItemAction,
              initEditData: _vm.handleInitEditData,
              setIsSelectedItem: _vm.setIsSelectedItem,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("delete-wastes", {
            attrs: {
              title: _vm.titleModalDelete,
              open: _vm.showModalDelete,
              typeDelete: _vm.typeDelete,
            },
            on: {
              closeModalOpen: _vm.closeModalOpen,
              handleDeleteAllWastesSelected: _vm.actionIsDeleteAllItems,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }