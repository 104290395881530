<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		v-model="isOpen"
		:title="title"
		size="xxl"
		centered
		cancel-variant="light"
		@show="resetModal"
		@hidden="emitEventClose"
		no-close-on-backdrop
		no-close-on-esc
		:modal-class="['custom-modal-min-width']"
	>
		<template #modal-header>
			<div class="d-flex align-items-center w-100">
				<div class="w-50">
					<h5 class="modal-title">{{ title }}</h5>
				</div>
				<div class="d-flex flex-row justify-content-end align-items-center w-50 gap-5">
					<component
						:is="getLucideIcon(ICONS.MESSAGE_CIRCLE.name)"
						:color="ICONS.MESSAGE_CIRCLE.color"
						:stroke-width="1.25"
						:size="30"
						:fill="ICONS.MESSAGE_CIRCLE.color"
					/>
					<button type="button" class="btn-close-custom-modal" @click="emitEventClose">×</button>
				</div>
			</div>
		</template>
		<div class="container-layout">
			<waste-form
				ref="form"
				:action="action"
				:enable-btn-report="false"
				:display-add-btn="false"
				:isDeleteAllItem="isDeleteAllItem"
				@setIsDeleteAllItemAction="setIsDeleteAllItemAction"
				:AllDepartments="AllDepartments"
				:premises="premises"
				:locations="locations"
				:wasteKeyModal="wasteKeyModal"
				:dataSlice="dataSlice"
				:value="value"
				@initEditData="handleInitEditData"
				:editData="editData"
				@setIsSelectedItem="setIsSelectedItem"
				:itemsSelected="itemsSelected"
			/>
		</div>
		<template #modal-footer>
			<div class="w-100">
				<b-row class="padding-c-1">
					<b-col cols="6">
						<div class="w-100 d-flex flex-row justify-content-start align-items-center">
							<b-button-group>
								<div class="position-check">
									<b-form-checkbox :disabled="setDisabledWhenNoRecording()" v-model="selectAll" size="lg" @change="handleInputSelectAll" />
									<b-button
										:disabled="itemsSelected.length === 0"
										size="md"
										variant="outline-none"
										class="btn-custom-delete w-138-px"
										@click="openModalDeleteAllSelected"
										>{{ FormMSG(4, 'Delete All') }} {{ setCompte() }}</b-button
									>
								</div>
							</b-button-group>
						</div>
					</b-col>
					<b-col cols="6">
						<div class="d-flex flex-row justify-content-end align-items-center w-100">
							<b-button size="md" variant="custom-outline-gray" class="w-138-px mr-3" :disabled="watingSubmitForm" @click="emitEventClose">
								{{ !btnCloseOnly ? FormMSG(1, 'Cancel') : FormMSG(3, 'Close') }}
							</b-button>
							<b-button size="md" variant="primary" class="w-138-px" :disabled="watingSubmitForm" @click="dispatchEvent" v-show="!btnCloseOnly">
								<b-spinner v-show="watingSubmitForm" small />
								<div :class="`${watingSubmitForm ? 'pl-2' : ''}`" style="margin-top: 1px">{{ FormMSG(2, 'Save') }}</div>
							</b-button>
						</div>
					</b-col>
				</b-row>
			</div>
		</template>
		<div>
			<delete-wastes
				:title="titleModalDelete"
				:open="showModalDelete"
				@closeModalOpen="closeModalOpen"
				@handleDeleteAllWastesSelected="actionIsDeleteAllItems"
				:typeDelete="typeDelete"
			/>
		</div>
	</b-modal>
</template>
<script>
import DeleteWastes from '../../views/waste/modal/DeleteWastes.vue';
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import WasteForm from '@/components/Waste/Form';
import { getLastWasteNumber, DelWastesDetailsSlice } from '@/cruds/waste.crud';
import _ from 'lodash';

export default {
	name: 'WasteFormModal',
	mixins: [LanguageMessages, GlobalMixin],
	data() {
		return {
			watingSubmitForm: false,
			btnCloseOnly: false,
			isDeleteAllItem: false,
			wasteKeyModal: {},
			dataSlice: [],
			selectAll: false,
			itemsSelected: [],
			showModalDelete: false,
			titleModalDelete: '',
			typeDelete: ''
		};
	},
	props: {
		value: {
			type: Boolean,
			required: false,
			default: false
		},
		title: {
			type: String,
			required: true,
			default: ''
		},
		action: {
			type: String,
			required: false,
			default: ''
		},
		AllDepartments: {
			type: Array,
			default: () => [],
			required: true
		},
		premises: {
			type: Array,
			default: () => [],
			required: false
		},
		locations: {
			type: Array,
			default: () => [],
			required: false
		},
		editData: {
			type: Object,
			default: () => ({}),
			required: false
		}
	},
	watch: {
		value: {
			handler(newVal) {
				return newVal;
			},
			immediate: true,
			deep: true
		},
		editData: {
			handler(newVal) {
				return newVal;
			},
			immediate: true,
			deep: true
		},
		action: {
			async handler(newVal) {
				if (newVal === 'ADD') {
					await this.getRecordingNumberInfoAdd();
					return newVal;
				} else if (newVal === 'UPDATE') {
					await this.getRecordingNumberInfoUpdate();
					this.dataSlice = this.editData.dataSlice;
					return newVal;
				}
				this.dataSlice = [];
				return newVal;
			},
			immediate: true,
			deep: true
		}
	},
	components: {
		WasteForm,
		DeleteWastes
	},
	computed: {
		isOpen: {
			get: function () {
				this.isDeleteAllItem = false;
				return this.value;
			},
			set: function (value) {
				return value;
			}
		}
	},
	methods: {
		setDisabledWhenNoRecording() {
			return this.$refs['form'] !== undefined && this.$refs['form'].recordingItems.length === 0 ? true : false;
		},
		setIsSelectedItem(data) {
			this.itemsSelected = data.filter((item) => item.isSelected);
			this.selectAll = false;
			if (this.itemsSelected.length === 0) {
				this.selectAll = false;
			}
			if (this.itemsSelected.length === this.$refs['form'].recordingItems.length) {
				this.selectAll = true;
			}
		},
		setCompte() {
			return '(' + this.itemsSelected.length.toString().padStart('2', '0') + ')';
		},
		closeModalOpen() {
			this.showModalDelete = false;
			this.typeDelete = '';
		},
		handleInputSelectAll(event) {
			this.selectAll = event;
			this.isDeleteAllItem = event;
			let timer = setTimeout(() => {
				if (event) {
					let data = this.$refs['form'].recordingItems;
					this.itemsSelected = data.filter((item) => item.isSelected);
					return;
				} else {
					this.itemsSelected = [];
					return;
				}
			}, [10]);
			return () => clearTimeout(timer);
		},
		async getRecordingNumberInfoAdd() {
			let number = await getLastWasteNumber();
			this.wasteKeyModal = {
				...this.wasteKeyModal,
				wasteNumber: +number,
				entryDate: Date.now(),
				date: Date.now()
			};
		},
		handleInitEditData() {
			this.$emit('initEditData');
		},
		async getRecordingNumberInfoUpdate() {
			let result = this.editData;
			this.wasteKeyModal = {
				...this.wasteKeyModal,
				id: result.waste.id,
				wasteNumber: result.waste.wasteNumber,
				entryDate: result.waste.entryDate,
				date: result.waste.entryDate,
				note: result.waste.note
			};
		},
		openModalDeleteAllSelected() {
			this.showModalDelete = true;
			this.typeDelete = 'AllItem';
			this.titleModalDelete = this.FormMSG(5, 'Are you sure you want to delete all entries for waste record?');
		},
		async actionIsDeleteAllItems() {
			if (this.itemsSelected.length !== 0) {
				let arrays_id = this.itemsSelected.map((item) => +item.id);
				let result = await DelWastesDetailsSlice(arrays_id);
				if (result) {
					const message = this.FormMSG(6, 'The entries for waste record delete successfully.');
					this.createToastForMobile('Success', message, '', 'success');
					this.itemsSelected = [];
					for (let i = 0; i < arrays_id.length; i++) {
						this.$refs['form'].recordingItems = this.$refs['form'].recordingItems.filter((item) => +item.id !== +arrays_id[i]);
					}
				}
			}
			this.showModalDelete = false;
			this.typeDelete = '';
			this.titleModalDelete = '';
			this.selectAll = false;
		},
		setIsDeleteAllItemAction() {
			this.isDeleteAllItem = false;
		},
		async emitEventClose() {
			if (this.$refs['form'] && this.$refs['form'].$refs['form-item']) {
				let arrays = [];
				let items = this.$refs['form'].$refs['form-item'];
				for (let i = 0; i < items.length; i++) {
					arrays = [...arrays, items[i].waste];
				}
				let arraysId = arrays.map((item) => ({ id: item.idToUpdate, isAddItem: item.isAddItem }));
				return this.$emit('waste-form-modal:closed', arraysId, this.action);
			}
			this.selectAll = false;
			this.itemsSelected = [];
			this.isDeleteAllItem = false;
			return this.$emit('waste-form-modal:closed', [], this.action);
		},
		async dispatchEvent(e) {
			e.preventDefault();
			let result = await this.$refs['form'].submitForm(e);
			if (result) {
				let wasteFormKey = this.$refs['form'].wasteFormKey;
				let data = this.$refs['form'].$refs['form-item'];
				let arrays = [];
				let allFiles = [];
				let allNewFilesUpdate = [];
				if (data !== undefined) {
					for (let i = 0; i < data.length; i++) {
						arrays = [...arrays, data[i].waste];
						allFiles = [...allFiles, ...data[i].files];
						allNewFilesUpdate = [...allNewFilesUpdate, ...data[i].newFilesUpdate];
					}
					this.$emit('waste-form:addUpdate', arrays, wasteFormKey, allFiles, allNewFilesUpdate);
				}
				this.selectAll = false;
				this.itemsSelected = [];
				this.isDeleteAllItem = false;
			}
		},
		onAccomodationAdded(payload) {
			this.$emit('accomodation-form-modal:new-accomodation-delivery-list', payload);
		},
		onAccomodationLoaded(payload) {
			this.watingSubmitForm = payload;
		},
		onAccomodationForceClosed(payload) {
			if (!_.isNil(payload) && payload === true) {
				this.$emit('accomodation-form-modal:closed');
			}
		},
		onAccomodationSubmitButtonHidden(payload) {
			if (!_.isNil(payload) && payload === true) {
				this.btnCloseOnly = true;
			}
		},
		resetModal() {
			this.btnCloseOnly = false;
		},
		onAccomodationPublished(payload) {
			this.$emit('accomodation-form-modal:all-accomodation-delivery', payload);
		}
	}
};
</script>
<style>
.padding-c-1 {
	padding-right: 30px;
	padding-left: 30px;
}
.position-check {
	display: flex;
	flex-direction: row !important;
	justify-content: center;
	align-items: center;
	padding-left: 1.875rem;
}
.btn-custom-delete {
	display: flex !important;
	width: auto;
}
.btn-close-custom-modal {
	display: flex;
	font-size: 2.5rem;
	line-height: 1;
	color: #000;
	text-shadow: 0 1px 0 #fff;
	opacity: 0.5;
	border: none;
	border-color: none;
	appearance: none;
	cursor: pointer;
	background-color: transparent;
	border: 0;
}
.custom-icon::before {
	fill: red;
}

.custom-modal-min-width .modal-dialog {
	min-width: 600px;
}
</style>
