<template>
	<errorContainer :error="error">
		<transition name="slide">
			<div id="greenReportingScreen" :class="$screen.width > 576 ? 'container-layout' : 'container-mobile'">
				<div class="form">
					<b-row>
						<b-col>
							<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">{{ FormMSG(1, 'Waste Management') }}</h1>
						</b-col>
					</b-row>
					<b-row class="mt-3">
						<b-col class="p-0">
							<b-card no-body>
								<form-modal
									:value="isWasteFormOpen"
									:title="titleFormModal"
									:action="action"
									@waste-form:addUpdate="onRecordingAddUpdate"
									@waste-form-modal:closed="onFormModalCancelled"
									:AllDepartments="AllDepartments"
									:premises="premises"
									:locations="locations"
									:editData="editData"
									@initEditData="handleInitEditData"
								/>
								<b-card-body class="pb-5">
									<fieldset class="scheduler-border border-groove-blue-streak pb-0">
										<legend class="scheduler-border text-color-blue-streak">
											{{ FormMSG(569, 'Reports') }}
										</legend>
										<div class="py-4">
											<div class="fw-700 fs-14 text-color-rhapsody-in-blue">
												{{ FormMSG(570, 'Standard type') }}
											</div>
											<b-row class="mt-0 pl-3 pr-3 mb-3">
												<div class="d-flex flex-row align-items-center p-1 rounded w-100">
													<div
														v-for="(item, index) of statusBarWasteStandard"
														:key="index"
														:style="{
															display: 'flex',
															backgroundColor: item.color,
															width: item.total + '%',
															borderTopLeftRadius: item.start ? '0.25rem!important' : '',
															borderBottomLeftRadius: item.start ? '0.25rem!important' : '',
															borderTopRightRadius: item.end ? '0.25rem!important' : '',
															borderBottomRightRadius: item.end ? '0.25rem!important' : '',
															borderRight: item.rightLine ? '2px solid white' : '',
															height: '100%',
															alignItems: 'center'
														}"
													>
														<span class="ml-2">{{ item.name }} {{ item.total + '%' }}</span>
													</div>
												</div>
											</b-row>
											<div class="fw-700 fs-14 text-color-rhapsody-in-blue">
												{{ FormMSG(571, 'Destruction type') }}
											</div>
											<b-row class="mt-0 pl-3 pr-3">
												<div class="d-flex flex-row align-items-center p-1 rounded w-100">
													<div
														v-for="(item, index) of statusBarWasteDestruction"
														:key="index"
														:style="{
															display: 'flex',
															backgroundColor: item.color,
															width: item.total + '%',
															borderTopLeftRadius: item.start ? '0.25rem!important' : '',
															borderBottomLeftRadius: item.start ? '0.25rem!important' : '',
															borderTopRightRadius: item.end ? '0.25rem!important' : '',
															borderBottomRightRadius: item.end ? '0.25rem!important' : '',
															borderRight: item.rightLine ? '2px solid white' : '',
															height: '100%',
															alignItems: 'center'
														}"
													>
														<span class="ml-2">{{ item.name }} {{ item.total + '%' }}</span>
													</div>
												</div>
											</b-row>
										</div>
									</fieldset>

									<b-row class="mb-2 mt-3">
										<b-col cols="4">
											<b-form-group class="mb-0">
												<b-input-group v-if="$screen.width >= 992">
													<b-form-input v-model="filter" type="text" id="filterInput" placeholder="Type to Search" />
													<b-input-group-append class="cursor-pointer">
														<b-input-group-text class="btn-search">
															<component
																:is="getLucideIcon('Search')"
																color="#FFFFFF"
																:size="16"
																class="icon"
																:stroke-width="2.5"
																v-if="filter.length === 0"
															/>
															<component
																:is="getLucideIcon('X')"
																color="#FFFFFF"
																:size="16"
																class="icon"
																:stroke-width="2.5"
																@click="filter = ''"
																v-else
															/>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="4" class="d-flex justify-content-center align-items-center">
											<div class="mr-3 text-color-australien pj-cb">
												<div class="fs-14">
													<b v-if="wastes.length > 0"
														>{{ recordOffSet * setPage }} - {{ pageNow() }} {{ FormMSG(32, 'of') }} {{ totalWasteDetail }}
														{{ FormMSG(33, 'records') }}</b
													>
													<b v-else>{{ FormMSG(34, 'No records') }}</b>
												</div>
											</div>
										</b-col>
										<b-col cols="4">
											<div class="float-right">
												<div class="d-flex w-100">
													<b-button @click="newRecording()" class="d-flex align-items-center mr-2 px-3" variant="primary">
														<component :is="getLucideIcon('Plus')" :size="16" />
														{{ FormMSG(5, 'New Recording') }}
													</b-button>
													<b-button
														id="unit-settings-button"
														@click="isWasteUnitSettingsOpen = true"
														class="d-flex align-items-center mr-2 px-3"
													>
														<i class="icon-settings mr-2"></i>
														{{ FormMSG(40, 'Unit settings') }}
													</b-button>
												</div>
											</div>
										</b-col>
									</b-row>
									<b-row class="mt-0">
										<b-col cols="12" xl="12">
											<div class="location-list-container">
												<b-table
													:selected-variant="selectedColor"
													:hover="hover"
													selectable
													:select-mode="selectMode"
													responsive="sm"
													:items="wastes"
													style="text-align: left"
													:fields="fields"
													:filter="filter"
													sticky-header="800px"
													bordered
													striped
													small
													:head-variant="hv"
													empty-text="No wastes found"
													show-empty
													:busy="isBusy"
													@row-clicked="handleEditWaste"
												>
													<template #table-busy>
														<div class="text-center text-primary my-2">
															<b-spinner class="align-middle"></b-spinner>
														</div>
													</template>
													<template #head(actionSelected)="data">
														<b-form-checkbox
															v-if="wastes.length > 0"
															v-model="selectAll"
															size="sm"
															@change="handleInputSelectAll"
														/>
													</template>
													<template #cell(actionSelected)="data">
														<b-form-checkbox
															v-model="data.item.checked"
															size="sm"
															@change="handleInputItemChecked($event, data.item)"
														/>
													</template>
													<template #cell(date)="data">
														<div>
															{{ data.item.date }}
														</div>
													</template>
													<template #cell(premises_location)="data">
														<div>
															{{ getLocationName(data.item.locationId) }}
														</div>
													</template>
													<template #cell(type)="data">
														<div class="d-flex flex-row align-items-center ml-3 gap-1">
															<div
																:style="{
																	width: '6px',
																	height: '6px',
																	backgroundColor: SetColorBackGround(data.item),
																	borderRadius: '50%'
																}"
															></div>
															<div>{{ setType(data.item) }}</div>
														</div>
													</template>
													<template #cell(measurement)="data">
														<div class="d-flex flex-row justify-content-center align-items-center" style="gap: 7px">
															<span>{{ data.item.kg }}</span>
															<b-badge variant="warning">{{ setMeasurement(data.item) }}</b-badge>
														</div>
													</template>
													<template #cell(disposal_type)="data">
														<div>{{ setDisposalType(data.item) }}</div>
													</template>
													<template #cell(amount)="data">
														<div>{{ rendCurrency(data.item.amount) }}</div>
													</template>
													<template #cell(reason)="data">
														<div>{{ getReason(data.item) }}</div>
													</template>
													<template #cell(totalCo2)="data">
														<div>{{ formatTotalCoTwoUnits(data.item.totalCo2) }}</div>
													</template>
													<template #cell(view)="data">
														<div v-if="data.item.images.length !== 0">
															<button class="btn-transparent" @click="showPictures(data.item.images)">
																<component :is="getLucideIcon('File')" :size="22" color="#47C7BF" />
															</button>
														</div>
													</template>
													<template #cell(options)="data">
														<div class="d-flex justify-content-center" style="min-width: 60px">
															<b-dropdown
																:id="`dropdown-green-action-${data.item.id}`"
																no-caret
																dropleft
																boundary="window"
																class="btn-transparent"
																variant="none"
																size="sm"
															>
																<template #button-content>
																	<component :is="getLucideIcon('MoreVertical')" :size="16" />
																</template>
																<div class="d-flex flex-column align-items-center">
																	<div class="d-flex flex-row align-items-center w-100">
																		<div class="d-flex justify-content-start ml-2 w-100">{{ FormMSG(7, 'Options') }}</div>
																		<div class="d-flex justify-content-end mr-1 w-100">
																			<component :is="getLucideIcon('X')" class="mr-1" :size="16" :stroke-width="2" />
																		</div>
																	</div>
																	<div class="d-flex flex-row w-100 divider-line"></div>
																	<div>
																		<b-dropdown-item class="small">
																			<span class="text-color-rhapsody-in-blue">
																				<component :is="getLucideIcon('File')" :size="16" color="#47C7BF" />&nbsp;{{
																					FormMSG(8, 'Report')
																				}}
																			</span>
																		</b-dropdown-item>
																		<b-dropdown-item class="small" @click="openModalDelete(data.item)">
																			<span class="text-color-rhapsody-in-blue">
																				<component
																					:is="getLucideIcon(ICONS.X_CIRCLE.name)"
																					:size="16"
																					:color="ICONS.X_CIRCLE.color"
																				/>&nbsp; {{ FormMSG(9, 'Delete') }}
																			</span>
																		</b-dropdown-item>
																		<b-dropdown-item class="small" @click="handleEditWaste(data.item)">
																			<span class="text-color-rhapsody-in-blue">
																				<component :is="getLucideIcon('Calendar')" color="#06263E" :size="16" />&nbsp;
																				{{ FormMSG(10, 'Audit log') }}
																			</span>
																		</b-dropdown-item>
																	</div>
																</div>
															</b-dropdown>
														</div>
													</template>
												</b-table>
											</div>
										</b-col>
									</b-row>
									<b-row>
										<b-col>
											<b-button size="sm" variant="custom-outline-primary" class="pl-5 pr-5 mr-3" @click="redirectPo">
												{{ FormMSG(11, 'Create PO') }}
											</b-button>
											<b-button
												size="sm"
												variant="custom-outline-gray"
												class="pl-5 pr-5 mr-3"
												:disabled="itemsSelected.length === 0"
												@click="openModalDeleteAllSelected"
											>
												{{ FormMSG(12, 'Delete') }} {{ setCompte() }}</b-button
											>
										</b-col>
										<b-col>
											<div class="float-right">
												<div class="d-flex w-100">
													<div class="mr-3 w-100-px">
														<v-select
															v-model="setPage"
															:options="pages"
															@option:selected="setPagesFunc"
															label="text"
															:reduce="(option) => option.value"
															:clearable="false"
														/>
													</div>
													<b-button
														variant="primary"
														size="sm"
														class="d-flex align-items-center mr-2 px-3"
														:disabled="positionNextPrev === 0 ? true : disableButtonPrev"
														@click="handleClickPrevious"
													>
														<component :is="getLucideIcon(ICONS.SKIP_BACK.name)" :color="ICONS.SKIP_BACK.color" :size="16" />
														<div class="ml-2" style="margin-top: 1px">{{ FormMSG(30, 'Prev') }}</div>
													</b-button>
													<b-button
														class="d-flex align-items-center px-3"
														variant="primary"
														size="sm"
														:disabled="disableButtonNext"
														@click="handleClickNext"
													>
														<div class="mr-2" style="margin-top: 1px">{{ FormMSG(31, 'Next') }}</div>
														<component :is="getLucideIcon(ICONS.SKIP_FORWARD.name)" :color="ICONS.SKIP_FORWARD.color" :size="16" />
													</b-button>
												</div>
											</div>
										</b-col>
									</b-row>
								</b-card-body>
							</b-card>
						</b-col>
					</b-row>
					<delete-wastes
						:title="titleModalDelete"
						:open="showModalDelete"
						@closeModalOpen="closeModalOpen"
						@handleDeleteWasteItem="handleDeleteWasteItem"
						@handleDeleteAllWastesSelected="handleDeleteAllWastesSelected"
						:typeDelete="typeDelete"
					/>
					<waste-unit-settings
						:value="isWasteUnitSettingsOpen"
						@waste-unit-settings-modal:save="finsihUnitsSettings()"
						@waste-unit-settings-modal:closed="cancelUnitsSettings()"
					/>
				</div>
			</div>
		</transition>
	</errorContainer>
</template>

<script>
import DeleteWastes from './modal/DeleteWastes.vue';
import WasteUnitSettings from './modal/WasteUnitSettings.vue';
import mapProps from '@/shared/vuePropsMapper';
import { isNil } from '@/shared/utils';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import FormModal from '@/components/Waste/FormModal';
import moment from 'moment';
import { getDepartments } from '@/cruds/department.crud';
import { getProjectLocationListForPremises } from '@/cruds/premises.crud';
import { getLocations } from '@/cruds/locations.crud';
import { getFileExtension } from '@/shared/helpers';
import {
	getAllDataWasteWithInitCache,
	getWastesDetailsSlice,
	delWasteDetail,
	getWasteDetailPercentage,
	addUpdWaste,
	addUpdWasteDetails,
	DelWastesDetailsSlice,
	getNumberPageWasteDetail
} from '@/cruds/waste.crud';
import Loading from 'vue-loading-overlay';
import CapturesManager from '@/components/Packages/Captures/capture.manager';
import { store } from '@/store';
import { rendCurrency, rendKgCo2 } from '~helpers';
import { setSubType } from '@/utils/utils';

const axios = require('axios').create();

export default {
	name: 'Waste',
	mixins: [languageMessages, globalMixin],
	props: {
		...mapProps(['parentType', 'parentSubType'], {
			type: String,
			required: false,
			default: null
		}),
		...mapProps(['multiple', 'canUploadPdf', 'carouselMode'], {
			type: Boolean,
			required: false,
			default: false
		}),
		hover: {
			type: Boolean,
			default: true
		}
	},
	components: {
		FormModal,
		Loading,
		DeleteWastes,
		WasteUnitSettings
	},

	data() {
		return {
			hv: 'dark',
			erreur: {},
			warning: '',
			locationPageType: 'add-edit',
			selectMode: 'single',
			selectedColor: 'primary',
			error: {},
			isLoading: true,
			loading: false,
			filter: '',
			isWasteFormOpen: false,
			isWasteUnitSettingsOpen: false,
			titleFormModal: '',
			action: '',
			AllDepartments: [],
			wastes: [],
			editData: {},
			selectAll: false,
			isBusy: false,
			rendCurrency,
			statusBare: [],
			statusBarWasteStandard: [],
			statusBarWasteDestruction: [],
			TYPES: [],
			DISPOSAL_TYPE: [],
			premises: [],
			locations: [],
			manager: new CapturesManager(this, {
				multiple: this.multiple
			}),
			showModalDelete: false,
			titleModalDelete: '',
			itemToDelete: null,
			itemsSelected: [],
			typeDelete: '',
			setSubType,
			setPage: 0,
			pages: [
				{ value: 10, text: '10' },
				{ value: 20, text: '20' },
				{ value: 50, text: '50' },
				{ value: 100, text: '100' },
				{ value: 200, text: '200' }
			],
			recordOffSet: 0,
			recordLimit: 10,
			disableButtonNext: false,
			disableButtonPrev: false,
			nextValueOffset: 0,
			positionNextPrev: 0,
			totalPage: 0,
			totalWasteDetail: 0,
			currentProject: store.getCurrentProjectConfig()
		};
	},
	created() {
		this.initialize();
	},
	watch: {},
	computed: {
		fields() {
			return [
				{
					key: 'actionSelected',
					label: '',
					class: 'text-center actionSelected',
					sortable: false
				},
				{
					key: 'date',
					label: this.FormMSG(13, 'Date'),
					formatter: (date) => {
						return moment(date).format('DD/MM/YYYY');
					},
					class: 'text-center',
					sortable: true
				},
				{
					key: 'department',
					label: this.FormMSG(14, 'Department'),
					class: 'text-center',
					sortable: true
				},
				{
					key: 'premises_location',
					label: this.FormMSG(15, 'Premises/Location'),
					class: 'text-center',
					sortable: true
				},
				{
					key: 'reason',
					label: this.FormMSG(35, 'Reason'),
					class: 'text-center',
					sortable: true
				},
				{
					key: 'type',
					label: this.FormMSG(16, 'Type'),
					class: 'text-center',
					sortable: true
				},
				{
					key: 'amount',
					label: this.FormMSG(17, 'Amount'),
					class: 'text-center',
					sortable: true
				},
				{
					key: 'measurement',
					label: this.FormMSG(18, 'Measurement'),
					class: 'text-center',
					sortable: true
				},
				{
					key: 'disposal_type',
					label: this.FormMSG(19, 'Disposal type'),
					class: 'text-center',
					sortable: true
				},
				{
					key: 'totalCo2',
					label: this.FormMSG(20, 'Total C02'),
					class: 'text-center',
					sortable: true
				},
				{
					key: 'view',
					label: this.FormMSG(21, 'View'),
					sortable: false,
					class: 'text-center'
				},
				{
					key: 'options',
					label: this.FormMSG(22, 'Options'),
					sortable: false,
					class: 'text-center'
				}
			];
		},
		captureOptions() {
			return this.manager.states.options;
		},
		standardUnits() {
			return [
				{ index: 1, value: this.currentProject.cardboardUnit },
				{ index: 2, value: this.currentProject.paperUnit },
				{ index: 3, value: this.currentProject.plasticUnit },
				{ index: 4, value: this.currentProject.foodUnit },
				{ index: 5, value: this.currentProject.generalWasteUnit },
				{ index: 6, value: this.currentProject.glassUnit }
			];
		},
		setDestructionUnits() {
			return [
				{ index: 1, value: this.currentProject.paintSpecialWasteUnit },
				{ index: 3, value: this.currentProject.woodUnit },
				{ index: 4, value: this.currentProject.steelUnit },
				{ index: 5, value: this.currentProject.plasticDestructionUnit }
			];
		}
	},

	methods: {
		async initialize() {
			this.$nextTick(() => {
				this.setPage = 10;
				this.getAllDepartments();
				this.reloadData();
				// this.getTypes();
				this.getDisposalTypes();
				this.getPremise();
				this.getLocation();
			});
		},
		formatTotalCoTwoUnits(CoTwoValue) {
			return rendKgCo2(CoTwoValue);
		},
		pageNow() {
			return (this.recordOffSet + 1) * this.setPage > this.totalWasteDetail ? this.totalWasteDetail : (this.recordOffSet + 1) * this.setPage;
		},
		cancelUnitsSettings() {
			this.isWasteUnitSettingsOpen = false;
		},
		async finsihUnitsSettings() {
			this.currentProject = store.getCurrentProjectConfig();
			await this.reloadData();
			this.isWasteUnitSettingsOpen = false;
		},
		async reloadData() {
			this.isBusy = true;
			let Filter = {
				recordLimit: this.recordLimit,
				recordOffSet: this.recordOffSet
			};
			let wasteDetailPages = await getNumberPageWasteDetail(this.setPage);
			let result = await getAllDataWasteWithInitCache(Filter);
			this.totalWasteDetail = wasteDetailPages[0];
			this.totalPage = wasteDetailPages[1];
			if (this.nextValueOffset + 1 === this.totalPage) {
				this.disableButtonNext = true;
			} else {
				this.disableButtonNext = false;
			}
			this.wastes = [...result].map((item) => {
				return {
					...item,
					id: +item.id,
					date: moment(item.waste.entryDate).format('DD/MM/YYYY'),
					department: item.departmentName,
					premises_location: item.locationName,
					type: item.type,
					typeText: this.setType(item),
					// amount: item.number,
					amount: 0,
					measurement: item.kg,
					disposal_type: item.disposalType,
					totalCo2: item.kgCoTwo,
					status: 0,
					checked: false,
					images: item.images
				};
			});
			this.isBusy = false;
			this.setLineBare();
			return this.wastes;
		},
		getLocationName(locationId) {
			let _location = this.locations.find((item) => +item.id === +locationId);
			if (_location !== undefined) {
				return _location.setName;
			}
			return '';
		},
		async getDataFromPage() {
			let Filter = {
				recordLimit: this.recordLimit,
				recordOffSet: this.recordOffSet
			};
			let result = await getAllDataWasteWithInitCache(Filter);
			this.wastes = [...result].map((item) => {
				return {
					...item,
					id: +item.id,
					date: moment(item.waste.entryDate).format('DD/MM/YYYY'),
					department: item.departmentName,
					premises_location: item.locationName,
					type: item.type,
					typeText: this.setType(item),
					// amount: item.number,
					amount: 0,
					measurement: item.kg,
					disposal_type: item.disposalType,
					totalCo2: item.kgCoTwo,
					status: 0,
					checked: false,
					images: item.images
				};
			});
			return this.wastes;
		},
		async setPagesFunc(item) {
			this.setPage = item.value;
			this.recordOffSet = 0;
			this.nextValueOffset = 0;
			this.positionNextPrev = 0;
			this.recordLimit = this.setPage;
			let wasteDetailPages = await getNumberPageWasteDetail(this.setPage);
			this.totalWasteDetail = wasteDetailPages[0];
			this.totalPage = wasteDetailPages[1];
			this.getDataFromPage();
			if (this.nextValueOffset + 1 === this.totalPage) {
				this.disableButtonNext = true;
			} else {
				this.disableButtonNext = false;
			}
		},
		handleClickPrevious() {
			--this.nextValueOffset;
			--this.positionNextPrev;
			this.disableButtonNext = false;
			this.recordLimit = this.setPage;
			this.recordOffSet = this.nextValueOffset;
			this.getDataFromPage();
		},
		handleClickNext() {
			++this.nextValueOffset;
			++this.positionNextPrev;
			if (this.nextValueOffset + 1 === this.totalPage) {
				this.disableButtonNext = true;
			} else {
				this.disableButtonNext = false;
			}
			this.recordLimit = this.setPage;
			this.recordOffSet = this.nextValueOffset;
			this.getDataFromPage();
		},
		redirectPo() {
			let url = window.location.href.split('#')[0];
			window.location.href = `${url}#/mypo`;
			let redirect = window.location.href;
			return redirect;
		},
		setCompte() {
			return '(' + this.itemsSelected.length.toString().padStart('2', '0') + ')';
		},
		showPictures(images) {
			if (isNil(images)) return;

			const list = images.map((img) => ({
				xid: img,
				src: `${process.env.VUE_APP_GQL}/images/${img.xid}`,
				thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + img,
				ext: img.ext
			}));

			this.$previewImages({
				images: list,
				options: {
					hideCropButton: true,
					hideDeleteButton: true
				}
			});
		},
		handleInitEditData() {
			this.titleFormModal = '';
			this.action = '';
			this.editData = {};
		},
		SetColorBackGround(data) {
			if (data.reason === 1) {
				switch (data.type) {
					case 1:
						return '#a4aebd';
					case 2:
						return '#41aba9';
					case 3:
						return '#9677d9';
					case 4:
						return '#5a6e48';
					case 5:
						return 'rgba(61, 59, 143, 0.43)';
					case 6:
						return 'rgba(0, 160, 156, 0.6)';
					default:
						return '#1a1917';
				}
			} else if (data.reason === 2) {
				switch (data.type) {
					case 1:
						return '#8fe8f7';
					case 2:
						return '#86ba8a';
					case 3:
						return '#aee06c';
					case 4:
						return '#e0c56c';
					case 5:
						return 'rgba(106, 186, 211, 0.68)';
					case 6:
						return 'rgba(234, 78, 44, 0.73)';
					case 7:
						return '#dfe3f5';
					default:
						return '#1a1917';
				}
			}
		},
		// getTypes() {
		// 	let dataForm = this.FormMenu(11014);
		// 	let uniteForm = this.FormMenu(11017);
		// 	let data = dataForm.map((item) => {
		// 		return {
		// 			text: item.text,
		// 			value: item.value,
		// 			uniteLabel: item.value === 13 ? uniteForm[1].text : uniteForm[0].text,
		// 			codeUnite: item.value === 13 ? uniteForm[1].value : uniteForm[0].value
		// 		};
		// 	});
		// 	this.TYPES = data;
		// 	return this.TYPES;
		// },
		getDisposalTypes() {
			let data = this.FormMenu(11021);
			this.DISPOSAL_TYPE = data;
			return this.DISPOSAL_TYPE;
		},
		setType(data) {
			let reason = data.reason;
			let value = data.type;
			if (reason === 1) {
				let response = [...this.FormMenu(1427)].filter((item) => +item.value === +value);
				if (response.length !== 0) {
					return response[0].text;
				}
				return '';
			}
			if (reason === 2) {
				let response = [...this.FormMenu(1428)].filter((item) => +item.value === +value);
				if (response.length !== 0) {
					return response[0].text;
				}
				return '';
			}
		},
		setMeasurement(item) {
			const uniteForm = this.FormMenu(1452);
			if (item.reason === 1) {
				const unit =
					this.standardUnits.filter((x) => x.index === item.type).length > 0
						? uniteForm[this.standardUnits.filter((x) => x.index === item.type)[0].value].text
						: uniteForm[0].text;
				return '' + unit;
				// return `${item.kg} ${unit}`;
			}
			if (item.reason === 2) {
				const unit =
					this.setDestructionUnits.filter((x) => x.index === item.type).length > 0
						? uniteForm[this.setDestructionUnits.filter((x) => x.index === item.type)[0].value].text
						: uniteForm[0].text;
				return '' + unit;
				// return `${item.kg} ${unit}`;
			}
			// let arrays = [
			// 	{ value: item.kg, unite: 'Kg' },
			// 	{ value: item.number, unite: 'Pi' },
			// 	{ value: item.liter, unite: 'L' }
			// ].filter((measure) => measure.value !== 0);
			// return `${arrays[0].value} ${arrays[0].unite}`;
		},
		getReason(item) {
			if (item !== undefined || item !== null) {
				let reason = item.reason;
				let menu = [...this.FormMenu(1434)].find((item) => +item.value === +reason);
				if (menu !== undefined) return menu.text;
				return '';
			}
			return item.reason;
		},
		setDisposalType(data) {
			let value = data.disposal_type;
			let response = this.DISPOSAL_TYPE.filter((item) => +item.value === +value);
			if (response.length !== 0) {
				return response[0].text;
			}
			return '';
		},
		async getAllDepartments() {
			let result = await getDepartments(true, false);
			this.AllDepartments = result.filter((dep) => dep.value !== 0);
			return this.AllDepartments;
		},
		async getPremise() {
			let result = await getProjectLocationListForPremises();
			this.premises = result;
			return this.premises;
		},
		async getLocation() {
			let result = await getLocations();
			this.locations = result;
			return this.locations;
		},

		async setLineBareForStarndardWaste() {
			let unique = await getWasteDetailPercentage(1);
			let size = unique.length;
			let data = unique.map((item, index) => {
				let indexOf = unique.indexOf(item) + 1;
				let data = {
					type: item.type,
					reason: 1
				};
				return {
					name: this.setType(item),
					start: index === 0 ? true : false,
					color: this.SetColorBackGround(data),
					rightLine: size === indexOf ? false : true,
					end: size === indexOf ? true : false,
					total: +item.percentage.toFixed(2)
				};
			});
			this.statusBarWasteStandard = data;
			return this.statusBarWasteStandard;
		},

		async setLineBareForDestructionWaste() {
			let unique = await getWasteDetailPercentage(2);
			let size = unique.length;
			let data = unique.map((item, index) => {
				let indexOf = unique.indexOf(item) + 1;
				let data = {
					type: item.type,
					reason: 2
				};
				return {
					name: this.setType(item),
					start: index === 0 ? true : false,
					color: this.SetColorBackGround(data),
					rightLine: size === indexOf ? false : true,
					end: size === indexOf ? true : false,
					total: +item.percentage.toFixed(2)
				};
			});
			this.statusBarWasteDestruction = data;
			return this.statusBarWasteDestruction;
		},

		async setLineBare() {
			await this.setLineBareForStarndardWaste();
			await this.setLineBareForDestructionWaste();
		},
		setDepartment(val) {
			return val;
		},
		async newRecording() {
			this.editData = {};
			this.titleFormModal = this.FormMSG(23, 'New Waste Recording');
			this.action = 'ADD';
			this.isWasteFormOpen = true;
		},
		onFormModalCancelled(arrays, isAction) {
			this.isWasteFormOpen = false;
			this.titleFormModal = '';
			this.action = '';
			this.editData = {};
			let arraysId = arrays.filter((item) => item.isAddItem !== false).map((_itemId) => _itemId.id);
			arraysId.length !== 0 && this.initDeleteAllWasteDetailId(arraysId);
			this.reloadData();
		},
		async initDeleteAllWasteDetailId(arrays_id) {
			let result = await DelWastesDetailsSlice(arrays_id);
			return result;
		},
		// commented by Lanja:
		// reason: Now in the new waste system, all value will be stored inside KG.

		// getColumn(payload) {
		// 	switch (payload) {
		// 		case 'kg':
		// 			return 'kg';
		// 		case 'li':
		// 			return 'liter';
		// 		default:
		// 			return 'number';
		// 	}
		// },
		// setNameValue(uniteType, value) {
		// 	let obj = {};
		// 	let _payload = uniteType.substr(0, 2).toString().toLowerCase();
		// 	let key = this.getColumn(_payload);
		// 	obj[key] = +value;
		// 	return obj;
		// },
		async onRecordingAddUpdate(arrays, wasteFormKey, allFiles, allNewFilesUpdate) {
			this.isWasteFormOpen = false;
			let arraysValidWaste = { entryDate: moment(wasteFormKey.date).utc().format(), wasteNumber: +wasteFormKey.wasteNumber, note: wasteFormKey.note };
			let arraysValidWasteDetails = arrays.map((item) => {
				return {
					wasteDetailIdUpdate: +item.idToUpdate,
					type: +item.type,
					disposalType: +item.disposalType,
					department: +item.department,
					// ...this.setNameValue(item.uniteType, item.measurement),
					kg: +item.measurement,
					locationId: +item.premisesLocation,
					reason: +item.reason
				};
			});
			let dataWaste = {
				WasteId: null,
				WasteInput: arraysValidWaste
			};
			let dataWasteDetails = {
				WasteId: +wasteFormKey.id,
				WasteDetailsInputs: arraysValidWasteDetails
			};
			if (allFiles !== undefined && allFiles.length !== 0 && this.action === 'ADD') {
				await this.actionFiles(allFiles);
			}
			if (allNewFilesUpdate !== undefined && allNewFilesUpdate.length !== 0 && this.action === 'UPDATE') {
				await this.actionFiles(allNewFilesUpdate);
			}
			this.addUpdateAllData(dataWaste, dataWasteDetails);
			this.titleFormModal = '';
			this.action = '';
		},
		async actionFiles(files) {
			let data = files;
			for (let i = 0; i < data.length; i++) {
				let parentId = data[i].parentId;
				let _data = data[i].itemWithFile;
				for (let j = 0; j < _data.length; j++) {
					await this.uploadFormData(_data[j].file, parentId, _data[j].fileTypeOrName);
				}
			}
		},
		async addUpdateAllData(dataWaste, dataWasteDetails) {
			let resultWaste = await addUpdWaste(dataWaste);
			if (resultWaste !== null) {
				let WasteId = +resultWaste.id;
				dataWasteDetails = {
					...dataWasteDetails,
					WasteId
				};
				await addUpdWasteDetails(dataWasteDetails);
			}
			this.reloadData();
		},
		async uploadFormData(file, parentId, payload) {
			let formData = new FormData();
			let parentSubType = this.setSubType(payload);
			formData.append('uploadimage', file);
			formData.append('parentType', 'waste_detail');
			formData.append('parentSubType', parentSubType);
			formData.append('parentId', +parentId);
			formData.append('fileName', file.name);
			formData.append('fileType', file.type);
			const urlStr = process.env.VUE_APP_GQL + '/' + 'upload';
			let result = axios
				.post(urlStr, formData, {
					headers: {
						Authorization: `Bearer ${store.state.token}`,
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(async (result) => {
					const filename = result.data;
					const xid = filename.substring(0, filename.lastIndexOf('.'));
					let data = {
						parentId: parentId,
						xid
					};
					return data;
				})
				.catch((e) => {
					console.error({ e });
				});
			return result;
		},
		async handleEditWaste(data) {
			let WasteNumber = +data.waste.wasteNumber;
			let res = await getWastesDetailsSlice(WasteNumber);
			this.editData = {
				...data,
				dataSlice: res.map((item) => ({ ...item, isAddItem: false, isSelected: false }))
			};
			if (this.editData !== null) {
				this.titleFormModal = this.FormMSG(24, 'Update Waste Recording');
				this.action = 'UPDATE';
				this.isWasteFormOpen = true;
			}
		},
		openModalDelete(data) {
			this.showModalDelete = true;
			this.typeDelete = 'OneItem';
			this.titleModalDelete = `${this.FormMSG(25, 'Are you sure you want to delete all entries for waste record number')} ${data.id}?`;
			this.itemToDelete = data;
		},
		openModalDeleteAllSelected() {
			this.showModalDelete = true;
			this.typeDelete = 'AllItem';
			this.titleModalDelete = `${this.FormMSG(26, 'Are you sure you want to delete all entries for waste record?')}`;
		},
		closeModalOpen() {
			this.showModalDelete = false;
			this.itemToDelete = null;
			this.typeDelete = '';
		},
		async handleDeleteWasteItem() {
			if (this.itemToDelete !== null) {
				let result = await delWasteDetail(this.itemToDelete.id);
				if (result) {
					this.wastes = this.wastes.filter((item) => item.id !== this.itemToDelete.id);
					this.setLineBare();
					const message = this.FormMSG(27, 'The entries for waste record delete successfully.');
					this.createToastForMobile('Success', message, '', 'success');
				} else {
					this.setLineBare();
					const message = this.FormMSG(28, 'The entries for waste record delete is an error.');
					this.createToastForMobile('warning', message, '', 'warning');
					this.reloadData();
				}
			}
			this.closeModalOpen();
		},
		async handleDeleteAllWastesSelected() {
			let sizeItemsSelected = [...this.itemsSelected].length;
			if (sizeItemsSelected !== 0) {
				let arrays_id = [...this.itemsSelected].map((item) => item.id);
				let result = await DelWastesDetailsSlice(arrays_id);
				if (result) {
					this.setLineBare();
					const message = this.FormMSG(29, 'The entries for waste record delete successfully.');
					this.createToastForMobile('Success', message, '', 'success');
				} else {
					this.setLineBare();
					const message = this.FormMSG(30, 'The entries for waste record delete is an error.');
					this.createToastForMobile('warning', message, '', 'warning');
				}
				this.reloadData();
			}
			this.closeModalOpen();
		},
		handleInputItemChecked(value, data) {
			this.wastes = this.wastes.map((item) => {
				if (item === data) {
					return { ...item, checked: value };
				}
				return item;
			});
			this.itemsSelected = this.wastes.filter((item) => item.checked).map((format) => ({ id: format.id, checked: format.checked }));
			let sizeItemsSelected = [...this.itemsSelected].length;
			let sizeWastes = [...this.wastes].length;
			if (sizeItemsSelected !== sizeWastes) {
				this.selectAll = false;
			} else {
				this.selectAll = true;
			}
		},
		handleInputSelectAll(value) {
			this.wastes = this.wastes.map((item) => ({ ...item, checked: value }));
			this.itemsSelected = this.wastes.filter((item) => item.checked).map((format) => ({ id: format.id, checked: format.checked }));
			let sizeItemsSelected = [...this.itemsSelected].length;
			let sizeWastes = [...this.wastes].length;
			if (sizeItemsSelected !== sizeWastes) {
				this.selectAll = false;
			} else {
				this.selectAll = true;
			}
		},
		handleClickReport() {
			this.loading = true;
			setTimeout(() => {
				this.loading = false;
			}, 1000);
		}
	}
};
</script>

<style scoped>
.gap-1 {
	gap: 5px;
}
#unit-settings-button {
	background-color: #126782ad;
	border-color: #126782ad;
}
#unit-settings-button:hover {
	background-color: #0b4d61ad;
}
</style>
