<template>
	<div>
		<div class="d-flex flex-row align-items-center justify-content-center mt-3 mb-3 w-100 gap-10">
			<div class="d-flex flex-row align-items-center justify-content-center" style="width: 40px">
				<b-form-group>
					<template v-slot:label>&nbsp;</template>
					<b-form-checkbox v-model="isChecked" @change="(e) => handleCheckItem(e, oneItem)" size="lg" />
				</b-form-group>
			</div>
			<div class="d-flex flex-row w-100" style="gap: 10px">
				<div class="d-flex flex-row" style="width: 40%; gap: 10px">
					<div class="d-flex flex-grow-1" style="width: 30%">
						<b-form-group :label="FormMSG(8, 'Reason')" :id="`type-${id}`">
							<treeselect
								v-model="waste.reason"
								:multiple="false"
								:options="mapReason"
								@select="(payload) => handleChange(payload, 'reason')"
								:disable-branch-nodes="true"
								:placeholder="FormMSG(20, 'Please select ...')"
								:clearable="false"
							>
								<div class="d-flex flex-row align-items-center" slot="value-label" slot-scope="{ node }">
									<div>{{ setTextValue(node.raw.text) }}</div>
								</div>
								<div slot="option-label" slot-scope="{ node }">
									<div class="treeselect-label item-select" :title="node.label">{{ node.label }}</div>
								</div>
							</treeselect>
						</b-form-group>
						<b-tooltip
							v-if="$v.waste.type.$error"
							:target="`type-${id}`"
							placement="bottom"
							triggers="manual"
							:show="$v.waste.type.$error"
							:show.sync="showTooltip"
						>
							{{ FormMSG(7, 'Required') }}
						</b-tooltip>
					</div>
					<div class="d-flex flex-grow-1" style="width: 30%">
						<b-form-group :label="FormMSG(1, 'Type')" :label-class="{ 'text-danger': $v.waste.type.$error }" :id="`type-${id}`">
							<treeselect
								v-model="waste.type"
								:multiple="false"
								:options="typesOptions"
								@select="(payload) => handleChange(payload, 'type')"
								:disable-branch-nodes="true"
								:class="{ 'is-invalid': $v.waste.type.$error }"
								:placeholder="FormMSG(20, 'Please select ...')"
							>
								<div class="d-flex flex-row align-items-center" slot="value-label" slot-scope="{ node }">
									<div>{{ setTextValue(node.raw.text) }}</div>
								</div>
								<div slot="option-label" slot-scope="{ node }">
									<div class="treeselect-label item-select" :title="node.label">{{ node.label }}</div>
								</div>
							</treeselect>
						</b-form-group>
						<b-tooltip
							v-if="$v.waste.type.$error"
							:target="`type-${id}`"
							placement="bottom"
							triggers="manual"
							:show="$v.waste.type.$error"
							:show.sync="showTooltip"
						>
							{{ FormMSG(7, 'Required') }}
						</b-tooltip>
					</div>
					<div class="d-flex flex-column flex-grow-1" style="width: 40%">
						<b-form-group style="" :label="FormMSG(2, 'Measurement')" :label-class="{ 'text-danger': $v.waste.measurement.$error }">
							<b-input-group>
								<b-form-input
									v-model="waste.measurement"
									type="number"
									min="0"
									step="1"
									placeholder="0"
									:class="{
										'is-invalid': $v.waste.measurement.$error
									}"
									style="height: 34px"
									:id="`measurement-${id}`"
								/>
								<b-input-group-append>
									<b-input-group-text style="background: #47c7bf; height: 34px">
										<span class="font-weight-bold fs-12" style="color: #fff" v-b-tooltip.hover.bottom.html="setUnite(waste.type)">{{
											setUniteText(setUnite(waste.type))
										}}</span>
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
						<b-tooltip
							v-if="$v.waste.measurement.$error"
							:target="`measurement-${id}`"
							placement="bottom"
							triggers="manual"
							:show="$v.waste.measurement.$error"
							:show.sync="showTooltip"
						>
							{{ FormMSG(7, 'Required') }}
						</b-tooltip>
					</div>
				</div>
				<div class="d-flex flex-row" style="width: 60%; gap: 10px">
					<div class="d-flex flex-grow-1">
						<b-form-group :label="FormMSG(3, 'Department')" :id="`department-${id}`">
							<treeselect
								v-model="waste.department"
								:multiple="false"
								:options="departmentsOptions"
								@select="(payload) => handleChange(payload, 'department')"
								:disable-branch-nodes="true"
								:placeholder="FormMSG(20, 'Please select ...')"
								@input="handleChangedDepartment"
							>
								<div class="d-flex flex-row align-items-center" slot="value-label" slot-scope="{ node }">
									<div>{{ setTextValue(node.raw.text) }}</div>
								</div>
								<div slot="option-label" slot-scope="{ node }">
									<div class="treeselect-label item-select" :title="node.label">{{ node.label }}</div>
								</div>
							</treeselect>
						</b-form-group>
						<!-- <b-tooltip
							v-if="$v.waste.department.$error"
							:target="`department-${id}`"
							placement="bottom"
							triggers="manual"
							:show="$v.waste.department.$error"
							:show.sync="showTooltip"
						>
							{{ FormMSG(7, 'Required') }}
						</b-tooltip> -->
					</div>
					<div class="d-flex flex-grow-1">
						<b-form-group :label="FormMSG(4, 'Premises/Location')" :id="`Premises-Location-${id}`">
							<treeselect
								v-model="waste.premisesLocation"
								:multiple="false"
								:options="premisesLocationOptions"
								@select="(payload) => handleChange(payload, 'premisesLocation')"
								:disable-branch-nodes="true"
								:placeholder="FormMSG(20, 'Please select ...')"
								@input="handleChangedPremise"
							>
								<div class="d-flex flex-row align-items-center" slot="value-label" slot-scope="{ node }">
									<div>{{ setTextValue(node.raw.text) }}</div>
								</div>
								<div slot="option-label" slot-scope="{ node }">
									<div class="treeselect-label item-select" :title="node.label">{{ node.label }}</div>
								</div>
							</treeselect>
						</b-form-group>
						<!-- <b-tooltip
							v-if="$v.waste.premisesLocation.$error"
							:target="`Premises-Location-${id}`"
							placement="bottom"
							triggers="manual"
							:show="$v.waste.premisesLocation.$error"
							:show.sync="showTooltip"
						>
							{{ FormMSG(7, 'Required') }}
						</b-tooltip> -->
					</div>
					<div class="d-flex flex-grow-1">
						<b-form-group :label="FormMSG(5, 'Disposal type')" :id="`disposal-type-${id}`">
							<treeselect
								v-model="waste.disposalType"
								:multiple="false"
								:options="disposalTypeOptions"
								@select="(payload) => handleChange(payload, 'disposalType')"
								:disable-branch-nodes="true"
								:placeholder="FormMSG(20, 'Please select ...')"
								@input="handleChangedDisposalType"
							>
								<div class="d-flex flex-row align-items-center" slot="value-label" slot-scope="{ node }">
									<div>{{ setTextValue(node.raw.text) }}</div>
								</div>
								<div slot="option-label" slot-scope="{ node }">
									<div class="treeselect-label item-select" :title="node.label">{{ node.label }}</div>
								</div>
							</treeselect>
						</b-form-group>
						<!-- <b-tooltip
							v-if="$v.waste.disposalType.$error"
							:target="`disposal-type-${id}`"
							placement="bottom"
							triggers="manual"
							:show="$v.waste.disposalType.$error"
							:show.sync="showTooltip"
						>
							{{ FormMSG(7, 'Required') }}
						</b-tooltip> -->
					</div>
				</div>
			</div>
			<div class="d-flex flex-row" style="width: 40px">
				<b-form-group>
					<template v-slot:label>&nbsp;</template>
					<b-button class="btn bg-transparent border-0" variant="primary" size="sm" @click="deleteItem(id)">
						<component :is="getLucideIcon(ICONS.MINUS_CIRCLE.name)" :color="ICONS.MINUS_CIRCLE.color" :size="18" />
					</b-button>
				</b-form-group>
			</div>
			<!--Attachment only-->
			<!-- <div class="d-flex flex-wrap flex-column waste-item-1">
				<b-form-group :label="FormMSG(6, 'Attachments')" :label-class="{ 'text-danger': $v.waste.attachement.$error }" :id="`attachments-${id}`">
					<treeselect
						v-model="waste.attachement"
						:multiple="false"
						:options="attachmentsOptions"
						@select="(payload) => handleChange(payload, 'attachement')"
						:disable-branch-nodes="true"
						:clearable="true"
						@clear="handleClearAttachement"
						:class="{ 'is-invalid': $v.waste.attachement.$error }"
						:placeholder="FormMSG(6, 'Attachments')"
						:disabled="!oneItem.isAddItem && action === 'UPDATE'"
					>
						<div class="d-flex flex-row align-items-center" slot="value-label" slot-scope="{ node }">
							<div>{{ setTextValue(node.raw.text) }}</div>
							<button class="btn-transparent">
								<component :is="getLucideIcon('File')" :size="20" color="#47C7BF" />
							</button>
						</div>
						<div class="d-flex flex-row align-items-center" slot="option-label" slot-scope="{ node }">
							<button v-if="node.id === 3" class="btn-transparent">
								<component :is="getLucideIcon(ICONS.PLUS_CIRCLE.name)" :size="22" :color="ICONS.PLUS_CIRCLE.color" />
							</button>
							<div v-if="node.id === 3" class="treeselect-label item-select">{{ FormMSG(8, 'Add attachments') }}</div>
							<div v-else class="treeselect-label item-select" :title="node.label">{{ node.label }}</div>
						</div>
					</treeselect>
				</b-form-group>
				<b-tooltip
					v-if="$v.waste.attachement.$error"
					:target="`attachments-${id}`"
					placement="bottom"
					triggers="manual"
					:show="$v.waste.attachement.$error"
					:show.sync="showTooltip"
				>
					{{ FormMSG(7, 'Required') }}
				</b-tooltip>
			</div> -->
		</div>
		<div>
			<component
				:is="renderItemAttachements()"
				:id="id"
				:title="FormMSG(6, 'Attachments')"
				:openModalFile="setActionModal"
				:isUsed="`waste`"
				@handleCloseModalFile="handleCloseModalFile"
				@changeImages="changeImages"
				@setAttachmentFiles="setAttachmentFiles"
			/>
		</div>
	</div>
</template>
<script>
import AllAttachementFileModal from './fileModal/AllAttachementFileModal.vue';
import CapturesPackage from '@/components/Packages/Captures/components/Main';
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import Treeselect from '@riophae/vue-treeselect';
import mapProps from '@/shared/vuePropsMapper';
import { validationMixin } from 'vuelidate';
import { required, decimal } from 'vuelidate/lib/validators';
import { greaterThanZero } from '@/shared/utils';
import { store } from '@/store';

export default {
	name: 'WasteFormItem',
	mixins: [LanguageMessages, GlobalMixin, validationMixin],
	components: {
		Treeselect,
		CapturesPackage,
		AllAttachementFileModal
	},
	props: {
		...mapProps(['multiple', 'canUploadPdf', 'carouselMode'], {
			type: Boolean,
			required: false,
			default: false
		}),
		handleDeleteItem: {
			type: Function,
			required: false
		},
		id: {
			type: String,
			required: false
		},
		AllDepartments: {
			type: Array,
			default: () => [],
			required: false
		},
		premises: {
			type: Array,
			default: () => [],
			required: false
		},
		locations: {
			type: Array,
			default: () => [],
			required: false
		},
		oneItem: {
			type: Object,
			default: () => ({}),
			required: false
		},
		action: {
			type: String,
			required: false
		}
	},
	components: {
		Treeselect
	},
	watch: {
		'$v.waste.measurement.$error': {
			handler(newValue) {
				if (newValue) {
					this.handleTooltip();
				}
			},
			immediate: true,
			deep: true
		},
		// '$v.waste.department.$error': {
		// 	handler(newValue) {
		// 		if (newValue) {
		// 			this.handleTooltip();
		// 		}
		// 	},
		// 	immediate: true,
		// 	deep: true
		// },
		// '$v.waste.premisesLocation.$error': {
		// 	handler(newValue) {
		// 		if (newValue) {
		// 			this.handleTooltip();
		// 		}
		// 	},
		// 	immediate: true,
		// 	deep: true
		// },
		// '$v.waste.disposalType.$error': {
		// 	handler(newValue) {
		// 		if (newValue) {
		// 			this.handleTooltip();
		// 		}
		// 	},
		// 	immediate: true,
		// 	deep: true
		// },
		'$v.waste.attachement.$error': {
			handler(newValue) {
				if (newValue) {
					this.handleTooltip();
				}
			},
			immediate: true,
			deep: true
		},
		oneItem: {
			handler(newVal) {
				if (newVal !== null) {
					if ([...Object.values(newVal)].some((item) => item === null)) {
						this.waste.idToUpdate = +newVal.id;
						this.isChecked = newVal.isSelected;
						this.waste.reason = +newVal.reason;
						newVal.type = this.waste.type;
						newVal.measurement = this.waste.measurement;
						newVal.department = this.waste.department;
						newVal.premisesLocation = this.waste.premisesLocation;
						newVal.unite = this.waste.unite;
						newVal.disposalType = this.waste.disposalType;
						newVal.uniteType = this.waste.uniteType;
						newVal.images = this.waste.images;
						newVal.attachement = this.waste.attachement;
					} else {
						this.waste.reason = +newVal.reason === 0 ? 1 : newVal.reason;
						this.waste.type = newVal.type;
						this.waste.measurement = this.setWasteItemMeasurement(newVal);
						this.waste.department = newVal.department === 0 ? null : newVal.department;
						this.waste.premisesLocation = newVal.locationId === 0 ? null : newVal.locationId;
						this.waste.disposalType = newVal.disposalType === 0 ? null : newVal.disposalType;
						this.waste.unite = newVal.disposalType === 0 ? 'Kg' : 'Liters';
						this.waste.uniteType = newVal.disposalType === 0 ? newVal.kg : newVal.liter;
						this.waste.idToUpdate = +newVal.id;
						this.waste.isAddItem = newVal.isAddItem;
						this.waste.images = newVal.images;
						this.isChecked = newVal.isSelected;
						let timer = setTimeout(async () => {
							if (newVal.images && newVal.images.length !== 0) {
								let data = newVal.images.map((img) => ({ images: img.xid + '.' + img.ext, originalFileName: img.originalFileName }));
								let dataImages = [];
								let parentId = +newVal.images[0].parentID;
								for (let i = 0; i < data.length; i++) {
									let nameImages = data[i].originalFileName;
									let getImages = await this.getImageFromFile(`${process.env.VUE_APP_GQL}/images/${data[i].images}`, nameImages);
									dataImages.push(getImages);
								}
								let imgData = [];
								for (let i = 0; i < dataImages.length; i++) {
									let obj = {
										itemWithFile: [{ file: dataImages[i], fileTypeOrName: null }],
										parentId
									};
									imgData.push(obj);
								}
								this.files = [...this.files, ...imgData];
								this.fileNames = newVal.images.map((item) => item.originalFileName).join(' / ');
							}
						}, [200]);
						() => clearTimeout(timer);
						this.waste.attachement = 3;
					}
					return newVal;
				}
				return newVal;
			},
			immediate: true,
			deep: true
		}
	},
	computed: {
		standardUnits() {
			return [
				{ index: 1, value: this.currentProject.cardboardUnit },
				{ index: 2, value: this.currentProject.paperUnit },
				{ index: 3, value: this.currentProject.plasticUnit },
				{ index: 4, value: this.currentProject.foodUnit },
				{ index: 5, value: this.currentProject.generalWasteUnit },
				{ index: 6, value: this.currentProject.glassUnit }
			];
		},
		setDestructionUnits() {
			return [
				{ index: 1, value: this.currentProject.paintSpecialWasteUnit },
				{ index: 3, value: this.currentProject.woodUnit },
				{ index: 4, value: this.currentProject.steelUnit },
				{ index: 5, value: this.currentProject.plasticDestructionUnit }
			];
		},
		setActionModal() {
			return this.openModalFile;
		},
		handleClearAttachement(e) {
			this.fileNames = '';
			this.files = [];
			return true;
		},
		mapReason() {
			let menu = [...this.FormMenu(1434)].map((item) => ({ ...item, label: item.text, id: item.value, text: item.text }));
			return menu;
		},
		typesOptions() {
			// let uniteForm = this.FormMenu(11018);
			const uniteForm = this.FormMenu(1452);
			if (this.waste.reason === 1) {
				let menu = [...this.FormMenu(1427)].map((item) => ({
					...item,
					label: item.text,
					id: item.value,
					text: item.text,
					uniteLabel:
						this.standardUnits.filter((x) => x.index === item.value).length > 0
							? uniteForm[this.standardUnits.filter((x) => x.index === item.value)[0].value].text
							: uniteForm[0].text,
					codeUnite: 1
					// codeUnite: this.standardUnits[item.value - 1] ? uniteForm[this.standardUnits[item.value - 1]].value : uniteForm[0].value
				}));
				return menu;
			}
			if (this.waste.reason === 2) {
				let menu = [...this.FormMenu(1428)].map((item) => ({
					...item,
					label: item.text,
					id: item.value,
					text: item.text,
					uniteLabel:
						this.setDestructionUnits.filter((x) => x.index === item.value).length > 0
							? uniteForm[this.setDestructionUnits.filter((x) => x.index === item.value)[0].value].text
							: uniteForm[0].text,
					codeUnite: 1
					// codeUnite: this.setDestructionUnits[item.value - 1] ? uniteForm[this.setDestructionUnits[item.value - 1]].value : uniteForm[0].value
				}));
				return menu;
			}
		},
		// typesOptions() {
		// 	let dataForm = [];
		// 	let uniteForm = [];
		// 	console.log(this.FormMSG(1427), this.FormMSG(1428));
		// 	if (store.getCurrentProjectConfig().greenReporting === 1) {
		// 		//type Greenshot options
		// 		dataForm = this.FormMenu(11014);
		// 		uniteForm = this.FormMenu(11017);
		// 	}
		// 	if (store.getCurrentProjectConfig().greenReporting === 2) {
		// 		// Type albert
		// 		dataForm = this.FormMenu(11015);
		// 		uniteForm = this.FormMenu(11018);
		// 	} else {
		// 		dataForm = this.FormMenu(11016);
		// 		uniteForm = this.FormMenu(11018);
		// 	}
		// 	let data = dataForm.map((item) => {
		// 		return {
		// 			label: item.text,
		// 			text: item.text,
		// 			id: item.value,
		// 			uniteLabel: item.value === 13 ? uniteForm[1].text : uniteForm[0].text,
		// 			codeUnite: item.value === 13 ? uniteForm[1].value : uniteForm[0].value
		// 		};
		// 	});
		// 	return data;
		// },
		departmentsOptions() {
			let data = this.AllDepartments.map((item) => ({
				id: +item.value,
				label: item.message,
				text: item.message
			})).filter((dep) => dep.value !== 0);
			return data;
		},
		premisesLocationOptions() {
			let data = this.locations.map((item) => ({ label: item.setName, id: +item.id, text: item.setName }));
			return data;
		},
		disposalTypeOptions() {
			let dataForm = this.FormMenu(11021);
			return dataForm.map((item) => ({ id: item.value, label: item.text, text: item.text }));
		},
		attachmentsOptions() {
			let data = [
				{
					id: 1,
					label: this.FormMSG(9, 'Receipt'),
					text: this.FormMSG(9, 'Receipt')
				},
				{
					id: 2,
					label: this.FormMSG(10, 'Invoice'),
					text: this.FormMSG(10, 'Invoice')
				},
				{
					id: 3,
					label: this.fileNames,
					text: this.fileNames
				}
			];
			return data;
		}
	},
	data() {
		return {
			selectedPerson: 0,
			options: null,
			types: [],
			premisesLocations: [],
			disposalTypes: [],
			attachements: [],
			departments: [],
			waste: {
				reason: 0,
				type: null,
				measurement: null,
				department: null,
				premisesLocation: null,
				disposalType: null,
				attachement: null,
				unite: null,
				uniteType: null,
				idToUpdate: null,
				isAddItem: false,
				images: []
			},
			openModalFile: false,
			fileNames: '',
			showTooltip: false,
			files: [],
			newFilesUpdate: [],
			isChecked: false,
			currentProject: store.getCurrentProjectConfig()
		};
	},
	created() {
		this.$nextTick(() => {
			this.initialize();
		});
	},
	methods: {
		handleCheckItem(event, item) {
			this.$emit('setReccordingSelect', { event, item });
		},
		setWasteItemMeasurement(item) {
			let value = [item.kg, item.number, item.liter].filter((measure) => measure !== 0);
			return value.reduce((a, b) => a + b, 0);
		},
		getFilenameFromURL(url) {
			const lastIndexOfSlash = url.lastIndexOf('/');
			return url.substring(lastIndexOfSlash + 1);
		},
		async getMimeTypeFromURL(url) {
			const response = await fetch(url);
			const contentType = response.headers.get('content-type');
			return contentType;
		},
		async getImageFromFile(url, name) {
			const response = await fetch(url);
			const blob = await response.blob();
			const filename = this.getFilenameFromURL(url);
			const mimeType = await this.getMimeTypeFromURL(url);
			const modifiedBlob = new Blob([blob], { type: mimeType });
			const modifiedFile = new File([modifiedBlob], name, { type: mimeType });

			return modifiedFile;
		},
		async initialize() {},
		setAttachmentFiles(data) {
			if (this.action === 'ADD') {
				this.files = [...this.files, { itemWithFile: data, parentId: +this.id }];
				this.fileNames = data.map((img) => img.file.name).join(' / ');
				this.waste.attachement = 3;
				this.openModalFile = false;
			} else {
				this.newFilesUpdate = [...this.newFilesUpdate, { itemWithFile: data, parentId: +this.id }];
				this.fileNames = data.map((img) => img.file.name).join(' / ');
				this.waste.attachement = 3;
				this.openModalFile = false;
			}
		},
		handleTooltip() {
			this.showTooltip = true;
			setTimeout(() => {
				this.showTooltip = false;
			}, 2000);
		},
		setTextValue(text) {
			//when attachment file exist _text.length> 6 && _text.substr(0,6)
			if (text !== undefined) {
				let _text = text.toString();
				if (_text.length > 18) {
					return _text.substr(0, 18) + '...';
				}
				return _text;
			}
		},
		renderItemAttachements() {
			return AllAttachementFileModal;
		},
		handleCloseModalFile(e) {
			this.openModalFile = e.value;
		},
		changeImages(e) {
			this.fileNames = e.data.join('-');
			this.openModalFile = e.value;
		},
		setUniteText(value) {
			// return value.toString().substr(0, 2);
			return value.toString();
		},
		setUnite(unite) {
			let data = this.typesOptions;
			let response = data.filter((item) => item.id === unite);
			if (response.length !== 0) {
				this.waste.unite = response[0].codeUnite;
				this.waste.uniteType = response[0].uniteLabel;
				return response[0].uniteLabel;
			}
			return 'Kg';
		},
		setNameValue(item) {
			let obj = { value: item };
			return obj;
		},
		handleChangedPremise(value) {
			if (value === undefined) {
				this.waste.premisesLocation = null;
			}
		},
		handleChangedDisposalType(value) {
			if (value === undefined) {
				this.waste.disposalType = null;
			}
		},
		handleChangedDepartment(value) {
			if (value === undefined) {
				this.waste.department = null;
			}
		},
		handleChange(payload, field) {
			this.waste[field] = payload.id;
			if (field === 'attachement' && payload.id === 3) {
				this.openModalFile = true;
			}
			if (field === 'reason') {
				this.waste.type = null;
			}
		},
		checkIsRequired() {
			this.$v.$touch();
			return this.$v.waste.$error;
		},
		async deleteItem(id) {
			let data = {
				id: id,
				action: this.action
			};
			return this.$emit('handleDeleteItem', data);
		}
	},
	validations() {
		const wasteValidate = {
			waste: {
				type: {
					required
				},
				measurement: {
					required,
					decimal,
					min: greaterThanZero
				}
				// department: {
				// 	required
				// },
				// premisesLocation: {
				// 	required
				// },
				// disposalType: {
				// 	required
				// }
				// attachement: {
				// 	required
				// }
			}
		};
		return wasteValidate;
	}
};
</script>
<style lang="scss">
.treeselect-label-show {
	display: flex;
	justify-content: center;
	align-items: center;
	width: auto;
	height: 30px;
	font-size: 14px;
	color: rgba(6, 38, 62, 0.74);
}
.divider-dotted {
	position: relative;
	margin: 8px 0 8px 0;
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: 0.145rem dotted rgba(226, 230, 235, 0.85);
}

.text-bold {
	font-weight: 600;
	font-size: 1rem;
}

.text-color {
	font-size: 12px !important;
	color: rgba(6, 38, 62, 0.84) !important;
}

.text-empty-person {
	margin-top: -8px;
	color: #ea4e2c;
	font-size: 13px;
	letter-spacing: 0.05em;
	font-weight: 600;
}
.gap-10 {
	gap: 10px;
}
.pd-10 {
	padding: 10px 0px;
}
.wd-150 {
	width: 150px;
}

.waste-item-1 {
	.vue-treeselect {
		&.is-invalid {
			.vue-treeselect__control {
				border-color: #ea4e2c !important;
			}
		}
		.vue-treeselect__control {
			display: flex;
			align-items: center;
			height: 32px !important;
			//when attachment exist
			//width: 150px;
			width: 190px;
			z-index: 0;
			.vue-treeselect__value-container {
				display: contents;
				.vue-treeselect__placeholder,
				.vue-treeselect__single-value {
					top: -1px;
				}
			}
			.vue-treeselect__x-container {
				margin-right: 2px;
			}
		}
	}
}
.waste-item-2 {
	.vue-treeselect {
		&.is-invalid {
			.vue-treeselect__control {
				border-color: #ea4e2c !important;
			}
		}
		.vue-treeselect__control {
			display: flex;
			align-items: center;
			height: 32px !important;
			z-index: 0;
			//when attachment exist
			//width: 110px;
			width: 195px;
			.vue-treeselect__value-container {
				display: contents;
				.vue-treeselect__placeholder,
				.vue-treeselect__single-value {
					top: -1px;
				}
			}
			.vue-treeselect__x-container {
				margin-right: 2px;
			}
		}
	}
}
.item-select {
	display: flex;
	align-items: center;
	height: 30px;
}
.custom-tooltip .tooltip-inner {
	background-color: #00000061;
	color: #ffffff;
}
.custom-tooltip .arrow::before {
	bottom: 0;
	border-width: 0 0.4rem 0.4rem;
	border-bottom-color: #00000061;
}
</style>
