var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        title: _vm.title,
        centered: "",
        size: "custom",
        scrollable: "",
        "cancel-variant": "light",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "modal-class": "custom-modal",
      },
      on: { hidden: _vm.handleCloseItemsModal },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-end align-items-center w-100 gap-5",
                },
                [
                  _c(
                    "b-button",
                    {
                      staticStyle: { width: "80px", "border-radius": "5px" },
                      attrs: { variant: "outline-danger", size: "sm" },
                      on: { click: _vm.handleCloseItemsModal },
                    },
                    [_vm._v(_vm._s(_vm.FormMSG(3, "Close")))]
                  ),
                  _c(
                    "b-button",
                    {
                      staticStyle: { width: "80px", "border-radius": "5px" },
                      attrs: {
                        disabled: _vm.fileItems.length === 0,
                        variant: "outline-success",
                        size: "sm",
                      },
                      on: { click: _vm.handleSaveItemsModal },
                    },
                    [_vm._v(_vm._s(_vm.FormMSG(4, "Save")))]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.setIsOpenModalFile,
        callback: function ($$v) {
          _vm.setIsOpenModalFile = $$v
        },
        expression: "setIsOpenModalFile",
      },
    },
    [
      _c("div", { staticClass: "d-flex flex-column" }, [
        _vm.fileItems.length === 0
          ? _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-center flex-row align-items-center w-100",
              },
              [_c("span", [_vm._v(_vm._s(_vm.FormMSG(1, "No data")))])]
            )
          : _c(
              "div",
              _vm._l(_vm.fileItems, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "d-flex flex-row align-items-center w-100",
                  },
                  [
                    _c("FileAttachementItems", {
                      ref: "file-attachment-item",
                      refInFor: true,
                      attrs: { id: item.id, item: item },
                      on: { deleteItemFile: _vm.deleteItemFile },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
        _c(
          "div",
          { staticClass: "d-flex flex-row justify-content-end w-100" },
          [
            _c(
              "div",
              { staticClass: "d-flex flex-row align-items-center mr-4" },
              [
                _c(
                  "button",
                  {
                    staticClass:
                      "d-flex flex-row align-items-center btn-transparent gap-5 w-100",
                    on: { click: _vm.handleAddNewFileItem },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(_vm.getLucideIcon(_vm.ICONS.PLUS_CIRCLE.name), {
                          tag: "component",
                          attrs: {
                            size: 22,
                            color: _vm.ICONS.PLUS_CIRCLE.color,
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", [
                      _c(
                        "div",
                        { staticClass: "treeselect-label item-select" },
                        [_vm._v(_vm._s(_vm.FormMSG(2, "Add attachment line")))]
                      ),
                    ]),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }