var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        size: "sm",
        centered: "",
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
        "hide-header": "",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c("div", { staticClass: "w-100" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-row justify-content-center text-center gap-10",
                  },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "btn-close",
                        attrs: { variant: "custom-outline-gray", size: "sm" },
                        on: { click: _vm.closeModalOpen },
                      },
                      [_vm._v(" Close ")]
                    ),
                    _c(
                      "b-button",
                      {
                        staticClass: "btn-confirm",
                        attrs: { variant: "primary", size: "sm" },
                        on: { click: _vm.handleDeleteWasteItem },
                      },
                      [_vm._v(" Yes ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.open,
        callback: function ($$v) {
          _vm.open = $$v
        },
        expression: "open",
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex justify-content-center align-items-center text-center w-100",
        },
        [_c("h5", [_vm._v(_vm._s(_vm.title))])]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }