var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.error } },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _c(
          "div",
          {
            class:
              _vm.$screen.width > 576 ? "container-layout" : "container-mobile",
            attrs: { id: "greenReportingScreen" },
          },
          [
            _c(
              "div",
              { staticClass: "form" },
              [
                _c(
                  "b-row",
                  [
                    _c("b-col", [
                      _c(
                        "h1",
                        {
                          class: [
                            `${!_vm.$screen.sm ? "main-page-title" : ""}`,
                            { "is-pwa": _vm.$isPwa() },
                          ],
                        },
                        [_vm._v(_vm._s(_vm.FormMSG(1, "Waste Management")))]
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  { staticClass: "mt-3" },
                  [
                    _c(
                      "b-col",
                      { staticClass: "p-0" },
                      [
                        _c(
                          "b-card",
                          { attrs: { "no-body": "" } },
                          [
                            _c("form-modal", {
                              attrs: {
                                value: _vm.isWasteFormOpen,
                                title: _vm.titleFormModal,
                                action: _vm.action,
                                AllDepartments: _vm.AllDepartments,
                                premises: _vm.premises,
                                locations: _vm.locations,
                                editData: _vm.editData,
                              },
                              on: {
                                "waste-form:addUpdate":
                                  _vm.onRecordingAddUpdate,
                                "waste-form-modal:closed":
                                  _vm.onFormModalCancelled,
                                initEditData: _vm.handleInitEditData,
                              },
                            }),
                            _c(
                              "b-card-body",
                              { staticClass: "pb-5" },
                              [
                                _c(
                                  "fieldset",
                                  {
                                    staticClass:
                                      "scheduler-border border-groove-blue-streak pb-0",
                                  },
                                  [
                                    _c(
                                      "legend",
                                      {
                                        staticClass:
                                          "scheduler-border text-color-blue-streak",
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(569, "Reports")
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "py-4" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "fw-700 fs-14 text-color-rhapsody-in-blue",
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    570,
                                                    "Standard type"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "b-row",
                                          {
                                            staticClass: "mt-0 pl-3 pr-3 mb-3",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex flex-row align-items-center p-1 rounded w-100",
                                              },
                                              _vm._l(
                                                _vm.statusBarWasteStandard,
                                                function (item, index) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: index,
                                                      style: {
                                                        display: "flex",
                                                        backgroundColor:
                                                          item.color,
                                                        width: item.total + "%",
                                                        borderTopLeftRadius:
                                                          item.start
                                                            ? "0.25rem!important"
                                                            : "",
                                                        borderBottomLeftRadius:
                                                          item.start
                                                            ? "0.25rem!important"
                                                            : "",
                                                        borderTopRightRadius:
                                                          item.end
                                                            ? "0.25rem!important"
                                                            : "",
                                                        borderBottomRightRadius:
                                                          item.end
                                                            ? "0.25rem!important"
                                                            : "",
                                                        borderRight:
                                                          item.rightLine
                                                            ? "2px solid white"
                                                            : "",
                                                        height: "100%",
                                                        alignItems: "center",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "ml-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.name) +
                                                              " " +
                                                              _vm._s(
                                                                item.total + "%"
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "fw-700 fs-14 text-color-rhapsody-in-blue",
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    571,
                                                    "Destruction type"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "mt-0 pl-3 pr-3" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex flex-row align-items-center p-1 rounded w-100",
                                              },
                                              _vm._l(
                                                _vm.statusBarWasteDestruction,
                                                function (item, index) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: index,
                                                      style: {
                                                        display: "flex",
                                                        backgroundColor:
                                                          item.color,
                                                        width: item.total + "%",
                                                        borderTopLeftRadius:
                                                          item.start
                                                            ? "0.25rem!important"
                                                            : "",
                                                        borderBottomLeftRadius:
                                                          item.start
                                                            ? "0.25rem!important"
                                                            : "",
                                                        borderTopRightRadius:
                                                          item.end
                                                            ? "0.25rem!important"
                                                            : "",
                                                        borderBottomRightRadius:
                                                          item.end
                                                            ? "0.25rem!important"
                                                            : "",
                                                        borderRight:
                                                          item.rightLine
                                                            ? "2px solid white"
                                                            : "",
                                                        height: "100%",
                                                        alignItems: "center",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "ml-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.name) +
                                                              " " +
                                                              _vm._s(
                                                                item.total + "%"
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "b-row",
                                  { staticClass: "mb-2 mt-3" },
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "4" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          { staticClass: "mb-0" },
                                          [
                                            _vm.$screen.width >= 992
                                              ? _c(
                                                  "b-input-group",
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "text",
                                                        id: "filterInput",
                                                        placeholder:
                                                          "Type to Search",
                                                      },
                                                      model: {
                                                        value: _vm.filter,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.filter = $$v
                                                        },
                                                        expression: "filter",
                                                      },
                                                    }),
                                                    _c(
                                                      "b-input-group-append",
                                                      {
                                                        staticClass:
                                                          "cursor-pointer",
                                                      },
                                                      [
                                                        _c(
                                                          "b-input-group-text",
                                                          {
                                                            staticClass:
                                                              "btn-search",
                                                          },
                                                          [
                                                            _vm.filter
                                                              .length === 0
                                                              ? _c(
                                                                  _vm.getLucideIcon(
                                                                    "Search"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    staticClass:
                                                                      "icon",
                                                                    attrs: {
                                                                      color:
                                                                        "#FFFFFF",
                                                                      size: 16,
                                                                      "stroke-width": 2.5,
                                                                    },
                                                                  }
                                                                )
                                                              : _c(
                                                                  _vm.getLucideIcon(
                                                                    "X"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    staticClass:
                                                                      "icon",
                                                                    attrs: {
                                                                      color:
                                                                        "#FFFFFF",
                                                                      size: 16,
                                                                      "stroke-width": 2.5,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.filter =
                                                                            ""
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        staticClass:
                                          "d-flex justify-content-center align-items-center",
                                        attrs: { cols: "4" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "mr-3 text-color-australien pj-cb",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "fs-14" },
                                              [
                                                _vm.wastes.length > 0
                                                  ? _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.recordOffSet *
                                                            _vm.setPage
                                                        ) +
                                                          " - " +
                                                          _vm._s(
                                                            _vm.pageNow()
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              32,
                                                              "of"
                                                            )
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.totalWasteDetail
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              33,
                                                              "records"
                                                            )
                                                          )
                                                      ),
                                                    ])
                                                  : _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            34,
                                                            "No records"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("b-col", { attrs: { cols: "4" } }, [
                                      _c(
                                        "div",
                                        { staticClass: "float-right" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "d-flex w-100" },
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  staticClass:
                                                    "d-flex align-items-center mr-2 px-3",
                                                  attrs: { variant: "primary" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.newRecording()
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    _vm.getLucideIcon("Plus"),
                                                    {
                                                      tag: "component",
                                                      attrs: { size: 16 },
                                                    }
                                                  ),
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          5,
                                                          "New Recording"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-button",
                                                {
                                                  staticClass:
                                                    "d-flex align-items-center mr-2 px-3",
                                                  attrs: {
                                                    id: "unit-settings-button",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.isWasteUnitSettingsOpen = true
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "icon-settings mr-2",
                                                  }),
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          40,
                                                          "Unit settings"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  { staticClass: "mt-0" },
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "12", xl: "12" } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "location-list-container",
                                          },
                                          [
                                            _c("b-table", {
                                              staticStyle: {
                                                "text-align": "left",
                                              },
                                              attrs: {
                                                "selected-variant":
                                                  _vm.selectedColor,
                                                hover: _vm.hover,
                                                selectable: "",
                                                "select-mode": _vm.selectMode,
                                                responsive: "sm",
                                                items: _vm.wastes,
                                                fields: _vm.fields,
                                                filter: _vm.filter,
                                                "sticky-header": "800px",
                                                bordered: "",
                                                striped: "",
                                                small: "",
                                                "head-variant": _vm.hv,
                                                "empty-text": "No wastes found",
                                                "show-empty": "",
                                                busy: _vm.isBusy,
                                              },
                                              on: {
                                                "row-clicked":
                                                  _vm.handleEditWaste,
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "table-busy",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-center text-primary my-2",
                                                        },
                                                        [
                                                          _c("b-spinner", {
                                                            staticClass:
                                                              "align-middle",
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                                {
                                                  key: "head(actionSelected)",
                                                  fn: function (data) {
                                                    return [
                                                      _vm.wastes.length > 0
                                                        ? _c(
                                                            "b-form-checkbox",
                                                            {
                                                              attrs: {
                                                                size: "sm",
                                                              },
                                                              on: {
                                                                change:
                                                                  _vm.handleInputSelectAll,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.selectAll,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.selectAll =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "selectAll",
                                                              },
                                                            }
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "cell(actionSelected)",
                                                  fn: function (data) {
                                                    return [
                                                      _c("b-form-checkbox", {
                                                        attrs: { size: "sm" },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleInputItemChecked(
                                                              $event,
                                                              data.item
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            data.item.checked,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              data.item,
                                                              "checked",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "data.item.checked",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "cell(date)",
                                                  fn: function (data) {
                                                    return [
                                                      _c("div", [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              data.item.date
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "cell(premises_location)",
                                                  fn: function (data) {
                                                    return [
                                                      _c("div", [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.getLocationName(
                                                                data.item
                                                                  .locationId
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "cell(type)",
                                                  fn: function (data) {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-row align-items-center ml-3 gap-1",
                                                        },
                                                        [
                                                          _c("div", {
                                                            style: {
                                                              width: "6px",
                                                              height: "6px",
                                                              backgroundColor:
                                                                _vm.SetColorBackGround(
                                                                  data.item
                                                                ),
                                                              borderRadius:
                                                                "50%",
                                                            },
                                                          }),
                                                          _c("div", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.setType(
                                                                  data.item
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "cell(measurement)",
                                                  fn: function (data) {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-row justify-content-center align-items-center",
                                                          staticStyle: {
                                                            gap: "7px",
                                                          },
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                data.item.kg
                                                              )
                                                            ),
                                                          ]),
                                                          _c(
                                                            "b-badge",
                                                            {
                                                              attrs: {
                                                                variant:
                                                                  "warning",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.setMeasurement(
                                                                    data.item
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "cell(disposal_type)",
                                                  fn: function (data) {
                                                    return [
                                                      _c("div", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.setDisposalType(
                                                              data.item
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "cell(amount)",
                                                  fn: function (data) {
                                                    return [
                                                      _c("div", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.rendCurrency(
                                                              data.item.amount
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "cell(reason)",
                                                  fn: function (data) {
                                                    return [
                                                      _c("div", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.getReason(
                                                              data.item
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "cell(totalCo2)",
                                                  fn: function (data) {
                                                    return [
                                                      _c("div", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.formatTotalCoTwoUnits(
                                                              data.item.totalCo2
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "cell(view)",
                                                  fn: function (data) {
                                                    return [
                                                      data.item.images
                                                        .length !== 0
                                                        ? _c("div", [
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn-transparent",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.showPictures(
                                                                        data
                                                                          .item
                                                                          .images
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getLucideIcon(
                                                                    "File"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      size: 22,
                                                                      color:
                                                                        "#47C7BF",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "cell(options)",
                                                  fn: function (data) {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex justify-content-center",
                                                          staticStyle: {
                                                            "min-width": "60px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "b-dropdown",
                                                            {
                                                              staticClass:
                                                                "btn-transparent",
                                                              attrs: {
                                                                id: `dropdown-green-action-${data.item.id}`,
                                                                "no-caret": "",
                                                                dropleft: "",
                                                                boundary:
                                                                  "window",
                                                                variant: "none",
                                                                size: "sm",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "button-content",
                                                                      fn: function () {
                                                                        return [
                                                                          _c(
                                                                            _vm.getLucideIcon(
                                                                              "MoreVertical"
                                                                            ),
                                                                            {
                                                                              tag: "component",
                                                                              attrs:
                                                                                {
                                                                                  size: 16,
                                                                                },
                                                                            }
                                                                          ),
                                                                        ]
                                                                      },
                                                                      proxy: true,
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex flex-column align-items-center",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex flex-row align-items-center w-100",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex justify-content-start ml-2 w-100",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                7,
                                                                                "Options"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex justify-content-end mr-1 w-100",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            _vm.getLucideIcon(
                                                                              "X"
                                                                            ),
                                                                            {
                                                                              tag: "component",
                                                                              staticClass:
                                                                                "mr-1",
                                                                              attrs:
                                                                                {
                                                                                  size: 16,
                                                                                  "stroke-width": 2,
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c("div", {
                                                                    staticClass:
                                                                      "d-flex flex-row w-100 divider-line",
                                                                  }),
                                                                  _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        "b-dropdown-item",
                                                                        {
                                                                          staticClass:
                                                                            "small",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "text-color-rhapsody-in-blue",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                _vm.getLucideIcon(
                                                                                  "File"
                                                                                ),
                                                                                {
                                                                                  tag: "component",
                                                                                  attrs:
                                                                                    {
                                                                                      size: 16,
                                                                                      color:
                                                                                        "#47C7BF",
                                                                                    },
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.FormMSG(
                                                                                      8,
                                                                                      "Report"
                                                                                    )
                                                                                  ) +
                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "b-dropdown-item",
                                                                        {
                                                                          staticClass:
                                                                            "small",
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.openModalDelete(
                                                                                  data.item
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "text-color-rhapsody-in-blue",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                _vm.getLucideIcon(
                                                                                  _vm
                                                                                    .ICONS
                                                                                    .X_CIRCLE
                                                                                    .name
                                                                                ),
                                                                                {
                                                                                  tag: "component",
                                                                                  attrs:
                                                                                    {
                                                                                      size: 16,
                                                                                      color:
                                                                                        _vm
                                                                                          .ICONS
                                                                                          .X_CIRCLE
                                                                                          .color,
                                                                                    },
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                "  " +
                                                                                  _vm._s(
                                                                                    _vm.FormMSG(
                                                                                      9,
                                                                                      "Delete"
                                                                                    )
                                                                                  ) +
                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "b-dropdown-item",
                                                                        {
                                                                          staticClass:
                                                                            "small",
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.handleEditWaste(
                                                                                  data.item
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "text-color-rhapsody-in-blue",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                _vm.getLucideIcon(
                                                                                  "Calendar"
                                                                                ),
                                                                                {
                                                                                  tag: "component",
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        "#06263E",
                                                                                      size: 16,
                                                                                    },
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " \n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                  _vm._s(
                                                                                    _vm.FormMSG(
                                                                                      10,
                                                                                      "Audit log"
                                                                                    )
                                                                                  ) +
                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ]),
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "pl-5 pr-5 mr-3",
                                            attrs: {
                                              size: "sm",
                                              variant: "custom-outline-primary",
                                            },
                                            on: { click: _vm.redirectPo },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(11, "Create PO")
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "pl-5 pr-5 mr-3",
                                            attrs: {
                                              size: "sm",
                                              variant: "custom-outline-gray",
                                              disabled:
                                                _vm.itemsSelected.length === 0,
                                            },
                                            on: {
                                              click:
                                                _vm.openModalDeleteAllSelected,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(12, "Delete")
                                                ) +
                                                " " +
                                                _vm._s(_vm.setCompte())
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("b-col", [
                                      _c(
                                        "div",
                                        { staticClass: "float-right" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "d-flex w-100" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "mr-3 w-100-px",
                                                },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      options: _vm.pages,
                                                      label: "text",
                                                      reduce: (option) =>
                                                        option.value,
                                                      clearable: false,
                                                    },
                                                    on: {
                                                      "option:selected":
                                                        _vm.setPagesFunc,
                                                    },
                                                    model: {
                                                      value: _vm.setPage,
                                                      callback: function ($$v) {
                                                        _vm.setPage = $$v
                                                      },
                                                      expression: "setPage",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-button",
                                                {
                                                  staticClass:
                                                    "d-flex align-items-center mr-2 px-3",
                                                  attrs: {
                                                    variant: "primary",
                                                    size: "sm",
                                                    disabled:
                                                      _vm.positionNextPrev === 0
                                                        ? true
                                                        : _vm.disableButtonPrev,
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.handleClickPrevious,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    _vm.getLucideIcon(
                                                      _vm.ICONS.SKIP_BACK.name
                                                    ),
                                                    {
                                                      tag: "component",
                                                      attrs: {
                                                        color:
                                                          _vm.ICONS.SKIP_BACK
                                                            .color,
                                                        size: 16,
                                                      },
                                                    }
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "ml-2",
                                                      staticStyle: {
                                                        "margin-top": "1px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            30,
                                                            "Prev"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-button",
                                                {
                                                  staticClass:
                                                    "d-flex align-items-center px-3",
                                                  attrs: {
                                                    variant: "primary",
                                                    size: "sm",
                                                    disabled:
                                                      _vm.disableButtonNext,
                                                  },
                                                  on: {
                                                    click: _vm.handleClickNext,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "mr-2",
                                                      staticStyle: {
                                                        "margin-top": "1px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            31,
                                                            "Next"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    _vm.getLucideIcon(
                                                      _vm.ICONS.SKIP_FORWARD
                                                        .name
                                                    ),
                                                    {
                                                      tag: "component",
                                                      attrs: {
                                                        color:
                                                          _vm.ICONS.SKIP_FORWARD
                                                            .color,
                                                        size: 16,
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("delete-wastes", {
                  attrs: {
                    title: _vm.titleModalDelete,
                    open: _vm.showModalDelete,
                    typeDelete: _vm.typeDelete,
                  },
                  on: {
                    closeModalOpen: _vm.closeModalOpen,
                    handleDeleteWasteItem: _vm.handleDeleteWasteItem,
                    handleDeleteAllWastesSelected:
                      _vm.handleDeleteAllWastesSelected,
                  },
                }),
                _c("waste-unit-settings", {
                  attrs: { value: _vm.isWasteUnitSettingsOpen },
                  on: {
                    "waste-unit-settings-modal:save": function ($event) {
                      return _vm.finsihUnitsSettings()
                    },
                    "waste-unit-settings-modal:closed": function ($event) {
                      return _vm.cancelUnitsSettings()
                    },
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }