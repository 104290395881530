var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        "header-class": "header-class-modal-doc-package",
        title: _vm.title,
        size: "xl",
        centered: "",
        "cancel-variant": "light",
        "modal-class": ["custom-modal-min-width"],
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function () {
            return [
              _c("div", { staticClass: "d-flex align-items-center w-100" }, [
                _c("div", { staticClass: "w-50" }, [
                  _c("h5", { staticClass: "modal-title" }, [
                    _vm._v(_vm._s(_vm.title)),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-row justify-content-end align-items-center w-50 gap-5",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn-close-custom-modal",
                        attrs: { type: "button" },
                        on: { click: _vm.cancelSettings },
                      },
                      [_vm._v("×")]
                    ),
                  ]
                ),
              ]),
            ]
          },
          proxy: true,
        },
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "w-100" },
                [
                  _c(
                    "b-row",
                    { staticClass: "padding-c-1" },
                    [
                      _c("b-col", { attrs: { cols: "12" } }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-row justify-content-end align-items-center w-100",
                          },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "w-138-px mr-3",
                                attrs: {
                                  size: "md",
                                  variant: "custom-outline-gray",
                                },
                                on: { click: _vm.cancelSettings },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      !_vm.btnCloseOnly
                                        ? _vm.FormMSG(1, "Cancel")
                                        : _vm.FormMSG(3, "Close")
                                    ) +
                                    "\n\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.btnCloseOnly,
                                    expression: "!btnCloseOnly",
                                  },
                                ],
                                staticClass: "w-138-px",
                                attrs: { size: "md", variant: "primary" },
                                on: { click: _vm.askForUnitsChange },
                              },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { "margin-top": "1px" } },
                                  [_vm._v(_vm._s(_vm.FormMSG(2, "Save")))]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c("div", { staticClass: "container-layout" }, [
        _c(
          "div",
          { staticClass: "mb-4" },
          [
            _c("h4", { staticClass: "card-title" }, [
              _c("strong", [_vm._v("Standard waste")]),
            ]),
            _c(
              "b-collapse",
              { attrs: { visible: true } },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "5" } },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    label: _vm.FormMSG(6, "Type"),
                                    "is-for-number": false,
                                    isDisable: true,
                                    addUnit: false,
                                  },
                                  model: {
                                    value: _vm.wasteTypes.standard.cardboard,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.wasteTypes.standard,
                                        "cardboard",
                                        $$v
                                      )
                                    },
                                    expression: "wasteTypes.standard.cardboard",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-form-group",
                                  { attrs: { label: _vm.FormMSG(7, "Unit") } },
                                  [
                                    _c("b-form-select", {
                                      attrs: {
                                        options: _vm.getUnitsList(
                                          _vm.kgGarbageContainerUnitsType
                                        ),
                                        id: "optionForUnits",
                                      },
                                      model: {
                                        value: _vm.dataToSend.cardboardUnit,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataToSend,
                                            "cardboardUnit",
                                            $$v
                                          )
                                        },
                                        expression: "dataToSend.cardboardUnit",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "5", offset: "1" } },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    label: _vm.FormMSG(6, "Type"),
                                    "is-for-number": false,
                                    isDisable: true,
                                    addUnit: false,
                                  },
                                  model: {
                                    value: _vm.wasteTypes.standard.generalWaste,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.wasteTypes.standard,
                                        "generalWaste",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "wasteTypes.standard.generalWaste",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-form-group",
                                  { attrs: { label: _vm.FormMSG(7, "Unit") } },
                                  [
                                    _c("b-form-select", {
                                      attrs: {
                                        options: _vm.getUnitsList(
                                          _vm.kgContainerUnitsType
                                        ),
                                        id: "optionForUnits",
                                      },
                                      model: {
                                        value: _vm.dataToSend.generalWasteUnit,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataToSend,
                                            "generalWasteUnit",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "dataToSend.generalWasteUnit",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "5" } },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    "is-for-number": false,
                                    isDisable: true,
                                    addUnit: false,
                                  },
                                  model: {
                                    value: _vm.wasteTypes.standard.paper,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.wasteTypes.standard,
                                        "paper",
                                        $$v
                                      )
                                    },
                                    expression: "wasteTypes.standard.paper",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("b-form-select", {
                                      attrs: {
                                        options: _vm.getUnitsList(
                                          _vm.kgGarbageContainerUnitsType
                                        ),
                                        id: "optionForUnits",
                                      },
                                      model: {
                                        value: _vm.dataToSend.paperUnit,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataToSend,
                                            "paperUnit",
                                            $$v
                                          )
                                        },
                                        expression: "dataToSend.paperUnit",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "5", offset: "1" } },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    "is-for-number": false,
                                    isDisable: true,
                                    addUnit: false,
                                  },
                                  model: {
                                    value: _vm.wasteTypes.standard.food,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.wasteTypes.standard,
                                        "food",
                                        $$v
                                      )
                                    },
                                    expression: "wasteTypes.standard.food",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("b-form-select", {
                                      attrs: {
                                        options: _vm.getUnitsList(
                                          _vm.kgGarbagesUnitsType
                                        ),
                                        id: "optionForUnits",
                                      },
                                      model: {
                                        value: _vm.dataToSend.foodUnit,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataToSend,
                                            "foodUnit",
                                            $$v
                                          )
                                        },
                                        expression: "dataToSend.foodUnit",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "5" } },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    "is-for-number": false,
                                    isDisable: true,
                                    addUnit: false,
                                  },
                                  model: {
                                    value: _vm.wasteTypes.standard.plastic,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.wasteTypes.standard,
                                        "plastic",
                                        $$v
                                      )
                                    },
                                    expression: "wasteTypes.standard.plastic",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("b-form-select", {
                                      attrs: {
                                        options: _vm.getUnitsList(
                                          _vm.kgGarbageContainerUnitsType
                                        ),
                                        id: "optionForUnits",
                                      },
                                      model: {
                                        value: _vm.dataToSend.plasticUnit,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataToSend,
                                            "plasticUnit",
                                            $$v
                                          )
                                        },
                                        expression: "dataToSend.plasticUnit",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "5", offset: "1" } },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    "is-for-number": false,
                                    isDisable: true,
                                    addUnit: false,
                                  },
                                  model: {
                                    value: _vm.wasteTypes.standard.glass,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.wasteTypes.standard,
                                        "glass",
                                        $$v
                                      )
                                    },
                                    expression: "wasteTypes.standard.glass",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("b-form-select", {
                                      attrs: {
                                        options: _vm.getUnitsList(
                                          _vm.kgBottleContainerType
                                        ),
                                        id: "optionForUnits",
                                      },
                                      model: {
                                        value: _vm.dataToSend.glassUnit,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataToSend,
                                            "glassUnit",
                                            $$v
                                          )
                                        },
                                        expression: "dataToSend.glassUnit",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("h4", { staticClass: "card-title" }, [
              _c("strong", [_vm._v("Set destruction waste")]),
            ]),
            _c(
              "b-collapse",
              { attrs: { visible: true } },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "5" } },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    label: _vm.FormMSG(6, "Type"),
                                    "is-for-number": false,
                                    isDisable: true,
                                    addUnit: false,
                                  },
                                  model: {
                                    value: _vm.wasteTypes.setDestruction.paint,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.wasteTypes.setDestruction,
                                        "paint",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "wasteTypes.setDestruction.paint",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-form-group",
                                  { attrs: { label: _vm.FormMSG(7, "Unit") } },
                                  [
                                    _c("b-form-select", {
                                      attrs: {
                                        options: _vm.getUnitsList(
                                          _vm.kgPaintLitersType
                                        ),
                                        id: "optionForUnits",
                                      },
                                      model: {
                                        value:
                                          _vm.dataToSend.paintSpecialWasteUnit,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataToSend,
                                            "paintSpecialWasteUnit",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "dataToSend.paintSpecialWasteUnit",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "5", offset: "1" } },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    label: _vm.FormMSG(6, "Type"),
                                    "is-for-number": false,
                                    isDisable: true,
                                    addUnit: false,
                                  },
                                  model: {
                                    value: _vm.wasteTypes.setDestruction.wood,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.wasteTypes.setDestruction,
                                        "wood",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "wasteTypes.setDestruction.wood",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-form-group",
                                  { attrs: { label: _vm.FormMSG(7, "Unit") } },
                                  [
                                    _c("b-form-select", {
                                      attrs: {
                                        options: _vm.getUnitsList(
                                          _vm.kgMetersCubeType
                                        ),
                                        id: "optionForUnits",
                                      },
                                      model: {
                                        value: _vm.dataToSend.woodUnit,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataToSend,
                                            "woodUnit",
                                            $$v
                                          )
                                        },
                                        expression: "dataToSend.woodUnit",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "5" } },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    "is-for-number": false,
                                    isDisable: true,
                                    addUnit: false,
                                  },
                                  model: {
                                    value:
                                      _vm.wasteTypes.setDestruction.plastic,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.wasteTypes.setDestruction,
                                        "plastic",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "wasteTypes.setDestruction.plastic",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("b-form-select", {
                                      attrs: {
                                        options: _vm.getUnitsList(
                                          _vm.kgMetersCubeType
                                        ),
                                        id: "optionForUnits",
                                      },
                                      model: {
                                        value:
                                          _vm.dataToSend.plasticDestructionUnit,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataToSend,
                                            "plasticDestructionUnit",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "dataToSend.plasticDestructionUnit",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "5", offset: "1" } },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c("custom-form-group", {
                                  attrs: {
                                    "is-for-number": false,
                                    isDisable: true,
                                    addUnit: false,
                                  },
                                  model: {
                                    value: _vm.wasteTypes.setDestruction.steel,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.wasteTypes.setDestruction,
                                        "steel",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "wasteTypes.setDestruction.steel",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("b-form-select", {
                                      attrs: {
                                        options: _vm.getUnitsList(
                                          _vm.kgMetersCubeType
                                        ),
                                        id: "optionForUnits",
                                      },
                                      model: {
                                        value: _vm.dataToSend.steelUnit,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataToSend,
                                            "steelUnit",
                                            $$v
                                          )
                                        },
                                        expression: "dataToSend.steelUnit",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "b-modal",
        {
          staticClass: "modal-warning",
          attrs: {
            "header-class": "header-class-modal-doc-package",
            "header-bg-variant": "warning",
            title: _vm.FormMSG(18, "Warning"),
            centered: "",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "w-100" },
                    [
                      _c(
                        "b-row",
                        { staticClass: "padding-c-1" },
                        [
                          _c("b-col", { attrs: { cols: "12" } }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-row justify-content-center align-items-center w-100",
                                staticStyle: { gap: "20px" },
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "w-100-px",
                                    attrs: {
                                      size: "md",
                                      variant: "outline-danger",
                                      disabled: _vm.watingSubmitForm,
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.showUnitChangeWarning = false
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(_vm.FormMSG(21, "No")) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                                _c(
                                  "b-button",
                                  {
                                    staticClass:
                                      "w-138-px d-flex justify-content-center align-items-center",
                                    attrs: {
                                      size: "md",
                                      variant: "success",
                                      disabled: _vm.watingSubmitForm,
                                    },
                                    on: { click: _vm.saveUnits },
                                  },
                                  [
                                    _c("b-spinner", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.watingSubmitForm,
                                          expression: "watingSubmitForm",
                                        },
                                      ],
                                      attrs: { small: "" },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        class: `${
                                          _vm.watingSubmitForm ? "pl-2" : ""
                                        }`,
                                        staticStyle: { "margin-top": "1px" },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(_vm.FormMSG(22, "Yes")) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.showUnitChangeWarning,
            callback: function ($$v) {
              _vm.showUnitChangeWarning = $$v
            },
            expression: "showUnitChangeWarning",
          },
        },
        [
          _c("div", [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(
                  _vm.FormMSG(
                    19,
                    "Your change will affect all units already used in this project."
                  )
                )
            ),
            _c("br"),
            _vm._v(
              "\n\t\t\t" +
                _vm._s(
                  _vm.FormMSG(20, "Are you sure you want to apply this update?")
                ) +
                "\n\t\t"
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }